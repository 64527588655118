define("bocce/components/modals/attachments/attachments-record-video", ["exports", "@glimmer/component", "moment"], function (_exports, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;
  /* global MediaStreamRecorder getSeekableBlob*/
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ModalsAttachmentsAttachmentsRecordVideoComponent = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, _dec23 = Ember._action, _dec24 = Ember._action, _dec25 = Ember._action, (_class = class ModalsAttachmentsAttachmentsRecordVideoComponent extends _component.default {
    get useCameraTag() {
      return $.isSafari;
    }
    constructor(owner, args) {
      super(owner, args);
      // UI switches
      _initializerDefineProperty(this, "expandedSettings", _descriptor, this);
      _initializerDefineProperty(this, "recordingVideo", _descriptor2, this);
      _initializerDefineProperty(this, "showEraseConfirmation", _descriptor3, this);
      _initializerDefineProperty(this, "showLoadingWheel", _descriptor4, this);
      // video settings
      _initializerDefineProperty(this, "videoSources", _descriptor5, this);
      _initializerDefineProperty(this, "audioSources", _descriptor6, this);
      _initializerDefineProperty(this, "quality", _descriptor7, this);
      _initializerDefineProperty(this, "audioInputId", _descriptor8, this);
      _initializerDefineProperty(this, "videoInputId", _descriptor9, this);
      _initializerDefineProperty(this, "echoCancellation", _descriptor10, this);
      // recording
      _initializerDefineProperty(this, "recordingStartTime", _descriptor11, this);
      _initializerDefineProperty(this, "recordingCurrentTime", _descriptor12, this);
      _initializerDefineProperty(this, "blobUrl", _descriptor13, this);
      _defineProperty(this, "stream", void 0);
      _defineProperty(this, "recorder", void 0);
      _defineProperty(this, "timeInterval", void 0);
      _defineProperty(this, "recordedVideo", void 0);
      this.expandedSettings = false;
      this.quality = 'sd';
      this.echoCancellation = localStorage.getItem('bocceEchoCancellation') === 'true' || false;
      this.recordingVideo = false;
      this.recordingCurrentTime = '00:00';
      if (this.useCameraTag) {
        this.setupCameraTag();
      } else {
        this.startPreview();
      }
    }
    startPreview() {
      this.showLoadingWheel = true;
      let vidSettings = {};
      let audioSettings = {};
      if (this.videoInputId) {
        vidSettings = {
          deviceId: this.videoInputId
        };
      }
      if (this.audioInputId) {
        audioSettings = {
          deviceId: this.audioInputId
        };
      }
      if (this.quality === 'hd') {
        vidSettings = {
          ...vidSettings,
          width: {
            min: 1280
          },
          height: {
            min: 720
          }
        };
      }
      // If false, set the audio settings to echoCancellation: false
      // Echo cancellation is set to true by default
      if (!this.echoCancellation) {
        audioSettings = {
          ...audioSettings,
          echoCancellation: false,
          mozAutoGainControl: false,
          mozNoiseSuppression: false,
          googEchoCancellation: false,
          googAutoGainControl: false,
          googNoiseSuppression: false,
          googHighpassFilter: false
        };
      }

      // if any streams are currently running, stop them
      if (this.stream) {
        this.stream.getTracks().forEach(track => track.stop());
      }
      navigator.mediaDevices.getUserMedia({
        audio: audioSettings,
        video: vidSettings
      }).then(stream => {
        navigator.mediaDevices.enumerateDevices().then(deviceInfos => {
          this.videoSources = deviceInfos.filter(deviceInfo => deviceInfo.kind === 'videoinput');
          this.audioSources = deviceInfos.filter(deviceInfo => deviceInfo.kind === 'audioinput');
        });
        let video = document.querySelector("#attachment-video-playback");
        video.volume = 0;
        video.srcObject = stream;
        let fileType = $.isSafari ? 'video/mp4' : 'video/webm';
        this.recorder = new MediaStreamRecorder(stream, {
          mimeType: fileType
        });
        this.stream = stream;
      }).catch(function (error) {
        console.log(error);
      });
    }
    setupCameraTag() {
      setTimeout(() => window.CameraTag.setup(), 500);
      window.CameraTag.observe('recorderAtt', 'published', () => {
        let myCamera = window.CameraTag.cameras['recorderAtt'],
          myVideo = myCamera.getVideo(),
          mp4_url = myVideo.medias.vga_mp4,
          /* eslint-disable-next-line ember/no-jquery */
          model_title = $('.floating-modal.active#assignment').length > 0 ? 'new-assignment' : this.get('model.title') || 'new-discussion',
          name = model_title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '') + '_video_' + new Date().getTime(),
          myVid = {
            isUrl: true,
            name: name,
            url: mp4_url,
            type: 'video/mp4'
          },
          cas;
        if (mp4_url) {
          myCamera.reset();
          // Add video to archive
          if (localStorage.localArchive) {
            cas = JSON.parse(localStorage.localArchive);
          } else {
            cas = {};
          }
          cas[myVid.name] = myVid;
          localStorage.localArchive = JSON.stringify(cas);
          this.args.addFile(myVid);
          this.args.closeModal();
        }
      }, true);
    }
    toggleAdditionalSettings() {
      this.expandedSettings = !this.expandedSettings;
    }
    changeVideoQuality(event) {
      this.quality = event.target.value;
      this.startPreview();
    }
    chooseAudioSource(event) {
      this.audioInputId = event.target.value;
      this.startPreview();
    }
    chooseVideoSource(event) {
      this.videoInputId = event.target.value;
      this.startPreview();
    }
    toggleEchoCancellation() {
      this.echoCancellation = !this.echoCancellation;
      localStorage.setItem('bocceEchoCancellation', this.echoCancellation);
      this.startPreview();
    }
    startRecording() {
      this.recorder.record();
      this.recordingVideo = true;
      this.startTimer();
    }
    startTimer() {
      this.recordingStartTime = (0, _moment.default)().unix();
      this.timeInterval = setInterval(() => {
        let timeCalc = (0, _moment.default)().unix() - this.recordingStartTime;
        this.recordingCurrentTime = (0, _moment.default)(timeCalc * 1000).format('mm:ss');
      }, 1000);
    }
    stopRecording() {
      clearInterval(this.timeInterval);
      this.recorder.stop(blob => {
        try {
          getSeekableBlob(blob, seekableBlob => {
            // Forcibly assign webm mimetype to blob
            seekableBlob = seekableBlob.slice(0, blob.size, 'video/webm');
            this.blobUrl = URL.createObjectURL(seekableBlob);
            this.recordedVideo = seekableBlob;
          });
        } catch (e) {
          Ember.debug("Could not create seekable blob because: ", e, "Creating non-seekable blob instead");
          blob = blob.slice(0, blob.size, 'video/webm');
          this.blobUrl = URL.createObjectURL(blob);
          this.recordedVideo = blob;
        }
        this.stream.stop();
        this.recorder.clearRecordedData();
      });
    }
    attachToPost() {
      if (this.blobUrl) {
        this.args.addFile(this.recordedVideo);
        this.args.closeModal();
      }
    }
    showConfirmation() {
      this.showEraseConfirmation = true;
    }
    cancelErase() {
      this.showEraseConfirmation = false;
    }
    reRecord() {
      this.recordingVideo = false;
      this.showEraseConfirmation = false;
      this.recordingCurrentTime = '00:00';
      this.blobUrl = undefined;
      this.stream = undefined;
      this.recorder.clearRecordedData();
      this.startPreview();
    }
    hideLoadingWheel() {
      this.showLoadingWheel = false;
    }
    willDestroy() {
      super.willDestroy();
      if (this.recorder) {
        this.recorder.stop(() => {
          this.recorder.clearRecordedData();
        });
      }
      if (this.stream) {
        this.stream.stop();
      }
      if (this.timeInterval) {
        clearInterval(this.timeInterval);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "expandedSettings", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "recordingVideo", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showEraseConfirmation", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showLoadingWheel", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "videoSources", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "audioSources", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "quality", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "audioInputId", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "videoInputId", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "echoCancellation", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "recordingStartTime", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "recordingCurrentTime", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "blobUrl", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleAdditionalSettings", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "toggleAdditionalSettings"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeVideoQuality", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "changeVideoQuality"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "chooseAudioSource", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "chooseAudioSource"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "chooseVideoSource", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "chooseVideoSource"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleEchoCancellation", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "toggleEchoCancellation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startRecording", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "startRecording"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "stopRecording", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "stopRecording"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "attachToPost", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "attachToPost"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showConfirmation", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "showConfirmation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelErase", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "cancelErase"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reRecord", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "reRecord"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hideLoadingWheel", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "hideLoadingWheel"), _class.prototype)), _class));
});