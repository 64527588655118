define("bocce/services/kaltura-upload", ["exports", "bocce/js/utils", "bocce/config/environment"], function (_exports, _utils, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let KalturaUpload = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class KalturaUpload extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _defineProperty(this, "kSession", void 0);
    }
    kalturaUploadVideo(vidBlob, failureCallback, isFile, blobURL, callback, progressCallback) {
      this.createToken().then(token => {
        if (!this.session) {
          this.session = window.session;
        }
        if (token) {
          let fd = new FormData();

          //Create backup link for plURL
          /* eslint-disable-next-line ember/no-jquery */
          $('.video-backup-rec').attr('href', this.blobURL);
          fd.append('ks', this.kSession);
          fd.append('resume', 'false');
          fd.append('finalChunk', 'true');
          fd.append('resumeAt', '-1');
          fd.append('fileData', vidBlob);
          fd.append('uploadTokenId', token.xml.result.id);
          /* eslint-disable-next-line ember/no-get */
          fd.append('fname', this.get('session.user.name').toLowerCase().replace(/ /g, '_') + '_section-' + this.get('session.course.id') + '_video_' + Math.round(new Date().getTime() / 1000));

          /* eslint-disable-next-line ember/no-jquery */
          $.ajax({
            xhr: () => {
              var xhr = new window.XMLHttpRequest();
              xhr.upload.addEventListener('progress', evt => {
                if (evt.lengthComputable) {
                  var percentComplete = evt.loaded / evt.total;
                  if (typeof isFile === 'number') {
                    progressCallback(percentComplete);
                  } else {
                    this.set('uploadPercentage', percentComplete + '%');
                  }
                }
              }, false);
              return xhr;
            },
            type: 'POST',
            url: token.xml.result.uploadUrl + '/api_v3/service/uploadtoken/action/upload',
            data: fd,
            processData: false,
            contentType: false
          }).done(data => {
            let dataJSON = (0, _utils.xmlToJson)(data);
            if (dataJSON.xml.result && dataJSON.xml.result.objectType === 'KalturaUploadToken') {
              this.createMediaEntry(token, vidBlob).then(videoInfo => {
                this.addToCategory('145227041', videoInfo.xml.result.rootEntryId);
                callback(this.attachRtc({
                  name: videoInfo.xml.result.name,
                  id: videoInfo.xml.result.rootEntryId
                }, isFile, blobURL));
              });
            } else {
              Ember.debug('Unable to create media entry for new video. Error Code:' + dataJSON.xml.result.error.code + ' Error Message: ' + dataJSON.xml.result.error.message);
            }
          }).fail(err => {
            this.failure(failureCallback, 'Kaltura upload failed.');
          });
        } else {
          Ember.debug('No session token provided. Aborting.');
          this.failure(failureCallback, 'Unable to get session token. Please contact support.');
        }
      });
    }
    failure(callback, message) {
      $.ajax({
        type: 'POST',
        url: '/interface/email',
        data: JSON.stringify({
          to: _environment.default.APP.email.kalturaStatus,
          from_name: 'BOCCE',
          subject: `kaltura failure`,
          html: `kaltura failure`
        }),
        success() {
          console.log("Successfully sent slack notification indicating no-requirements for this course.");
        },
        error(jqXHR, textStatus) {
          console.error('Unable to send slack notification indicating no-requirements for this course.');
        }
      });
      callback(message);
    }
    attachRtc(videoContents, removeIndex, blobURL) {
      let myVid = {
        isUrl: true,
        name: videoContents.name,
        url: 'https://cdnapisec.kaltura.com/p/2588802/sp/258880200/playManifest/entryId/' + videoContents.id + '/format/url/protocol/https/flavorParamId/4128/name/course_video.mp4',
        created_at_formatted: moment.tz('America/New_York').format('MMM Do[,] h:mm:ss a') + " ET",
        type: 'video/mp4'
      };
      if (videoContents && videoContents.id.length > 0) {
        if (blobURL) {
          let fileExt = myVid.name.substr((~-myVid.name.lastIndexOf('.') >>> 0) + 2),
            className = `extension-${fileExt}`.toLowerCase();

          // Create the record with its default parameters, and then add it to the collection.
          let record = {
            file: myVid,
            valid: true,
            uploaded: false,
            deleted: false,
            className: className,
            blobURL: blobURL,
            uploaded_id: videoContents.id
          };
          return record;
        } else {
          return {
            file: myVid,
            uploaded_id: videoContents.id
          };
        }
      }
    }
    addToCategory(cat_id, entry_id) {
      /* eslint-disable-next-line ember/no-jquery */
      $.ajax({
        type: 'POST',
        url: 'https://www.kaltura.com/api_v3/service/categoryentry/action/add',
        data: {
          ks: this.kSession,
          categoryEntry: {
            entryId: entry_id,
            categoryId: cat_id,
            objectType: 'KalturaCategoryEntry'
          }
        },
        success: category => {
          let categoryJSON = (0, _utils.xmlToJson)(category);
          if (typeof categoryJSON === 'object' && categoryJSON.xml.result.error) {
            Ember.debug('Unable to add video category. Error Code:' + categoryJSON.xml.result.error.code + ' Error Message: ' + categoryJSON.xml.result.error.message);
          }
        },
        error: error => {
          Ember.debug('Category error: ' + error.responseText);
        }
      });
    }
    kalturaRenameVideo(videoId, newName) {
      this.createToken().then(token => {
        if (!token) {
          Ember.debug('No session token provided. Aborting.');
        }
        fetch('https://www.kaltura.com/api_v3/service/media/action/update', {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ks: this.kSession,
            entryId: videoId,
            mediaEntry: {
              name: newName
            }
          })
        }).then(resp => resp.text()).then(str => (0, _utils.xmlToJson)(new window.DOMParser().parseFromString(str, "text/xml"))).then(({
          xml
        }) => {
          if (xml.result.error) {
            console.debug("Kaltura rename failed because:", xml.result.error.message);
          }
        });
      });
    }
    createMediaEntry(rToken, file) {
      /* eslint-disable-next-line ember/no-get */
      let userName = this.get('session.user.name').toLowerCase().replace(/ /g, '_');
      /* eslint-disable-next-line ember/no-get */
      let courseId = this.get('session.course.id');
      let data = {
        ks: this.kSession,
        entry: {
          'mediaType': 1,
          'name': file.name || userName + '_section-' + courseId + '_video_' + Math.round(new Date().getTime() / 1000),
          'description': 'User uploaded video from course ID: ' + courseId + ', uploaded by user: ' + userName
        }
      };
      return new Promise(resolve => {
        /* eslint-disable-next-line ember/no-jquery */
        $.ajax({
          url: 'https://www.kaltura.com/api_v3/service/media/action/add',
          data: data,
          success: mediaEntry => {
            let mediaJSON = (0, _utils.xmlToJson)(mediaEntry);
            if (typeof mediaJSON === 'object' && mediaJSON.xml.result.error) {
              Ember.debug('Unable to create media entry for new video. Error Code:' + mediaJSON.xml.result.error.code + ' Error Message: ' + mediaJSON.xml.result.error.message);
              resolve(false);
            } else if (typeof mediaJSON === 'object' && mediaJSON.xml.result.objectType === 'KalturaMediaEntry') {
              let rootEntryId = mediaJSON.xml.result.rootEntryId;
              /* eslint-disable-next-line ember/no-jquery */
              $.ajax({
                url: 'https://www.kaltura.com/api_v3/service/media/action/addContent',
                data: {
                  ks: this.kSession,
                  entryId: rootEntryId,
                  resource: {
                    objectType: 'KalturaUploadedFileTokenResource',
                    token: rToken.xml.result.id
                  }
                },
                success: token => {
                  let tokenJSON = (0, _utils.xmlToJson)(token);
                  if (typeof tokenJSON === 'object' && tokenJSON.xml.result.error) {
                    Ember.debug('Unable to add video to media entry. Error Code:' + tokenJSON.xml.result.error.code + ' Error Message: ' + tokenJSON.xml.result.error.message);
                    resolve(false);
                  } else if (typeof tokenJSON === 'object' && tokenJSON.xml.result.objectType === 'KalturaMediaEntry') {
                    resolve(tokenJSON);
                  } else {
                    resolve(false);
                    Ember.debug('Kaltura returned malformed token');
                  }
                },
                error: error => {
                  Ember.debug('Kaltura media error: ' + error.responseText);
                  resolve(false);
                }
              });
            } else {
              Ember.debug('Unable to create media entry for uploaded video file');
            }
          },
          error: error => {
            Ember.debug('Kaltura session start error: ' + error.responseText);
            resolve(false);
          }
        });
      });
    }
    createToken() {
      return new Promise(resolve => {
        if (!this.store) {
          this.store = window.store;
        }
        this.store.findRecord('session', 1).then(session => {
          let kaltura_session = session.get('kaltura');
          this.kSession = kaltura_session;
          /* eslint-disable-next-line ember/no-jquery */
          $.ajax({
            url: 'https://www.kaltura.com/api_v3/service/uploadtoken/action/add',
            data: {
              ks: kaltura_session
            },
            success: token => {
              //Convert XML token
              let tokenJSON = (0, _utils.xmlToJson)(token);
              if (typeof tokenJSON === 'object' && tokenJSON.xml.result.error) {
                Ember.debug('Unable to create upload token. Error Code:' + tokenJSON.xml.result.error.code + ' Error Message: ' + tokenJSON.xml.result.error.message);
                resolve(false);
              } else if (typeof tokenJSON === 'object' && tokenJSON.xml.result.objectType === 'KalturaUploadToken') {
                resolve(tokenJSON);
              } else {
                Ember.debug('Kaltura returned malformed token');
              }
            },
            error: error => {
              Ember.debug('Kaltura token request error: ' + error.responseText);
              resolve(false);
            }
          });
        });
      });
    }
    parseFile(file, callback) {
      let fileSize = file.size,
        chunkSize = 64 / Math.pow(1024, 2),
        // 64mb chunk size
        offset = 0,
        chunkReaderBlock = null;
      let readEventHandler = function (evt) {
        if (evt.target.error === null) {
          offset += evt.target.result.length;
          callback(evt.target.result); // callback for handling read chunk
        } else {
          Ember.debug('Read error: ' + evt.target.error);
          return;
        }
        if (offset >= fileSize) {
          Ember.debug('Done reading file');
          return;
        }

        // of to the next chunk
        chunkReaderBlock(offset, chunkSize, file);
      };
      chunkReaderBlock = function (_offset, length, _file) {
        var r = new FileReader();
        var blob = _file.slice(_offset, length + _offset);
        r.onload = readEventHandler;
        r.readAsText(blob);
      };

      // now let's start the read with the first block
      chunkReaderBlock(offset, chunkSize, file);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});