define("bocce/components/rubric/assessment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const RubricAssessment2 = Ember.Component.extend({
    assessmentObserver: Ember.observer('submission.rubric_assessments.@each.{points,comments}', function () {
      this.setData();
    })
  });
  let RubricAssessment = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember.inject.service('rubric'), _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._action, (_class = class RubricAssessment extends RubricAssessment2 {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "detailsShown", _descriptor, this);
      _initializerDefineProperty(this, "data", _descriptor2, this);
      _initializerDefineProperty(this, "rubricService", _descriptor3, this);
      _initializerDefineProperty(this, "store", _descriptor4, this);
      _initializerDefineProperty(this, "session", _descriptor5, this);
    }
    toggleDetailsShown() {
      this.detailsShown = !this.detailsShown;
    }
    init() {
      super.init(...arguments);
      this.setData();
    }
    setData() {
      let data = [];
      this.get('criteria').forEach(criterion => {
        let assessment = this.store.peekRecord('assessment', `${this.get('submission_id')}_${this.get('submission.attempt')}_${criterion.get('id')}`);
        data.push({
          assessment,
          criterion
        });
      });
      this.data = data;
    }
    get hasAssessments() {
      let data = this.data;
      if (data) {
        return this.data.some(d => d.assessment?.points);
      } else {
        return false;
      }
    }
    get lateDeduction() {
      let assessment = this.store.peekRecord('assessment', `${this.get('submission_id')}_${this.get('submission.attempt')}_-1`);
      return assessment?.get('points');
    }
    get lateDeductionComment() {
      let assessment = this.store.peekRecord('assessment', `${this.get('submission_id')}_${this.get('submission.attempt')}_-1`);
      return assessment?.get('comments');
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "detailsShown", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "data", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "rubricService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleDetailsShown", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "toggleDetailsShown"), _class.prototype)), _class));
});