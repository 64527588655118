define("bocce/components/lessons/advice-card-submitter", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AdviceCardSubmitter = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember.computed('session.section.adviceCards.adviceCardsCount', 'adviceCardsArray'), _dec12 = Ember.computed('session.user.id'), _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, (_class = class AdviceCardSubmitter extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "adviceCardBodyText", _descriptor2, this);
      _initializerDefineProperty(this, "overCharLimit", _descriptor3, this);
      _initializerDefineProperty(this, "adviceCardPostable", _descriptor4, this);
      _initializerDefineProperty(this, "recordingUploading", _descriptor5, this);
      _initializerDefineProperty(this, "attachmentURL", _descriptor6, this);
      _initializerDefineProperty(this, "consentChecked", _descriptor7, this);
      _initializerDefineProperty(this, "adviceCard", _descriptor8, this);
      _initializerDefineProperty(this, "myAdviceCard", _descriptor9, this);
      _initializerDefineProperty(this, "adviceCardsArray", _descriptor10, this);
      this.fetchAdviceCards();
    }
    get adviceCards() {
      if (this.session.section.adviceCards.adviceCardsCount) {
        this.fetchAdviceCards();
      }
      return this.adviceCardsArray;
    }
    fetchAdviceCards() {
      let section = window.session.get('section.id');
      return fetch(`/interface/submit-advice-card/${section}`, {
        method: 'GET'
      }).then(response => {
        // Make sure to check if the response is OK before proceeding
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        // Use the .json() method to parse the response body as JSON
        return response.json();
      }).then(data => {
        // 'data' is the JSON object that was parsed from the response body
        if (data.adviceCards.map(card => card.id).toSorted().toString() != this.adviceCards.map(card => card.id).toSorted().toString()) {
          this.adviceCardsArray = Ember.A(data.adviceCards);
        }
        if (!this.isInstructor) {
          this.updateMyAdviceCard();
        }
        return this.adviceCardsArray;
      }).catch(error => {
        // Handle any errors that occurred during the fetch
        console.error('There was a problem with your fetch operation:', error);
        return [];
      });
    }
    get otherAdviceCards() {
      let userId = parseInt(window.session.get('user.id'));
      if (this.adviceCards) {
        return this.adviceCards.filter(c => parseInt(c.author_canvas_id) != userId);
      }
      return [];
    }
    updateMyAdviceCard() {
      let userId = parseInt(window.session.get('user.id'));
      if (this.adviceCards) {
        let adviceCard = this.adviceCards.find(function (card) {
          return parseInt(card.author_canvas_id) === userId;
        });
        this.myAdviceCard = adviceCard;
      } else {
        return false;
      }
    }
    get isInstructor() {
      let userId = parseInt(window.session.get('user.id'));
      return window.session.section.teachers.find(user => {
        return parseInt(user.id) === userId;
      });
    }
    updateRecordingStatus(status) {
      this.recordingUploading = status;
      this.adviceCardPostable = this.adviceCardBodyText.length > 0 && !this.recordingUploading;
    }
    resubmitAdviceCard() {
      this.myAdviceCard = false;
    }
    updateBody(newText) {
      this.adviceCardBodyText = newText;
      this.adviceCardPostable = this.adviceCardBodyText.length > 0 && !this.recordingUploading;
    }
    updateOverCharLimit(newVal) {
      this.overCharLimit = newVal;
    }
    updateAttachment(attachmentURL) {
      this.attachmentURL = attachmentURL;
      this.recordingUploading = false;
    }
    async toggleAcceptAdviceCard(id, advIndex) {
      let accept = !this.adviceCards[advIndex].accepted;
      let response = await fetch(`/interface/submit-advice-card/${window.session.get('section.id')}/accept`, {
        method: 'PUT',
        body: JSON.stringify({
          id: id,
          accepted: accept
        })
      });
      response = await response.json();
      if (response.success) {
        // Check this.adviceCards for the card with the id and update it with the accepted status
        let advIndex = this.adviceCards.findIndex(function (card) {
          return card.id === id;
        });
        if (advIndex !== -1) {
          // Dealing with deep arrays in Ember is a bit tricky...
          // Step 1 & 2: Make a copy of the object and update it
          const updatedCard = {
            ...this.adviceCards[advIndex],
            accepted: accept
          };
          // Step 3: Create a new array with the updated object
          const newAdviceCards = this.adviceCards.map((card, index) => index === advIndex ? updatedCard : card);
          // Step 4: Set the new array to the state
          this.adviceCardsArray = newAdviceCards;
        }
      }
      this.session.set('section.adviceCards.adviceCardsCount', 0);
    }
    async updateCheckbox(event) {
      this.consentChecked = event.target.checked;
    }
    async submitAdviceCard() {
      let assignmentBody = this.adviceCardBodyText;
      let attachmentURL = this.attachmentURL;
      let publicize_consent = this.consentChecked;
      let author_name = window.session.get('user.name');
      let course = window.session.get('course.id');
      let course_code = window.session.get('course.code').split('.')[0];
      let course_title = window.session.get('course.title');
      let term = window.session.get('course.term.name');
      let userId = parseInt(window.session.get('user.id'));
      let existing_card = this.adviceCards.find(function (card) {
        return parseInt(card.author_canvas_id) === userId;
      }) || null;
      let thisCard = {
        body: assignmentBody,
        attachment: attachmentURL,
        author_name: author_name,
        section_id: window.session.get('section.id'),
        course_id: course,
        course_code: course_code,
        course_title: course_title,
        instructor_id: null,
        submission_id: 0,
        term: term,
        publicize_consent: publicize_consent
      };
      if (existing_card) {
        thisCard.id = existing_card.id;
      }
      let response = await fetch(`/interface/submit-advice-card/`, {
        method: 'POST',
        body: JSON.stringify(thisCard)
      });
      if (response.ok) {
        // Need id of new card to prevent dupe
        this.fetchAdviceCards();
        this.args.submitAdviceCard();
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "adviceCardBodyText", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "overCharLimit", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "adviceCardPostable", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "recordingUploading", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "attachmentURL", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "consentChecked", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "adviceCard", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.model;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "myAdviceCard", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "adviceCardsArray", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "adviceCards", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "adviceCards"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isInstructor", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "isInstructor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateRecordingStatus", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "updateRecordingStatus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resubmitAdviceCard", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "resubmitAdviceCard"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateBody", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "updateBody"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateOverCharLimit", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "updateOverCharLimit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateAttachment", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "updateAttachment"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleAcceptAdviceCard", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "toggleAcceptAdviceCard"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateCheckbox", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "updateCheckbox"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submitAdviceCard", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "submitAdviceCard"), _class.prototype)), _class));
});