define("bocce/components/quiz-stats/types/short-answer-question-statistics", ["exports", "bocce/utilities/dialog", "bocce/js/utils"], function (_exports, _dialog, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let ShortAnswerQuestionStatistics = _exports.default = (_dec = Ember._action, (_class = class ShortAnswerQuestionStatistics extends Ember.Component {
    async showUsers(users) {
      let usersHtml = `<table class="question-stats-usernames">
                        <tbody>`;
      for (let user of users.sort()) {
        usersHtml += `<tr>
                       <td>${user}</td>
                     </tr>`;
      }
      usersHtml += '</tbody></table>';
      (0, _dialog.default)('', ['OK'], usersHtml, 0);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "showUsers", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "showUsers"), _class.prototype)), _class));
});