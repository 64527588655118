define("bocce/mixins/support/util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.any = any;
  _exports.bindAll = bindAll;
  _exports.encodeImageFileAsURL = encodeImageFileAsURL;
  _exports.max = max;
  _exports.min = min;
  _exports.mixin = mixin;
  _exports.randomString = randomString;
  _exports.shuffle = shuffle;
  _exports.sortAsc = sortAsc;
  _exports.sortDesc = sortDesc;
  _exports.unique = unique;
  function shuffle(arr) {
    let l = arr.length;
    for (let i = l - 1; i >= 1; i--) {
      let n = Math.floor(Math.random() * i),
        tmp = arr[n];
      arr[n] = arr[i];
      arr[i] = tmp;
    }
  }
  function any(arr, func) {
    // This could be arr.filter(func).length > 0, but looping lets us short circuit
    let l = arr.length;
    for (let i = 0; i < l; i++) {
      if (func(arr[i])) {
        return true;
      }
    }
    return false;
  }
  function unique(arr) {
    return Array.from(new Set(arr));
  }
  function sortAsc(arr) {
    return arr.sort((a, b) => a > b ? 1 : -1);
  }
  function sortDesc(arr) {
    return arr.sort((a, b) => a > b ? -1 : 1);
  }
  function max(arr) {
    return sortDesc(arr)[0];
  }
  function min(arr) {
    return sortAsc(arr)[0];
  }
  function bindAll(obj, fields = false) {
    for (let fn in obj) {
      if (typeof obj[fn] !== 'function' || fields && fields.indexOf(fn) === -1) {
        continue;
      }
      obj[fn] = obj[fn].bind(obj);
    }
  }
  function mixin(dest, src, no_overwrites) {
    for (let k in src) {
      if (!dest[k] || !no_overwrites) {
        dest[k] = src[k];
      }
    }
  }
  function encodeImageFileAsURL(element) {
    let file = element.files[0];
    return new Promise(function (resolve, reject) {
      var fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result);
        // console.log(fr.result);
      };
      fr.onerror = reject;
      fr.readAsDataURL(file);
    });
  }
  function randomString(length) {
    let text = '';
    let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
});