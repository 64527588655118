define("bocce/components/quiz-stats/question-statistics-multiple", ["exports", "bocce/utilities/dialog", "bocce/mixins/boot"], function (_exports, _dialog, _boot) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const QuestionStatisticsMultiple2 = Ember.Component.extend(_boot.default, {});

  //Used for Fill in Multiple Blanks and Multiple Dropdowns.

  //this.statistics gets passed in to the component. Origin is canvas quiz stats api.
  let QuestionStatisticsMultiple = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class QuestionStatisticsMultiple extends QuestionStatisticsMultiple2 {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "placeholderId", _descriptor, this);
      _initializerDefineProperty(this, "answers", _descriptor2, this);
    }
    init(...args) {
      super.init(...args);
      this.placeholderId = this.statistics.answer_sets[0].text;
      this.blankPercentages = this.getBlankPercentages();
      this.setAnswers();
    }
    didInsertElement() {
      const self = this;

      //When the user clicks on a placeholder, change the placeholder id. This triggers the stats to be recalculated
      //and then re-rendered. After this happens, show the stats html inside a modal.
      Ember.$(this.element).find(`.question-stats-placeholder`).off('click.questionStatsPlaceholder').on('click.questionStatsPlaceholder', event => {
        self.changePlaceholder(event.target.textContent);
        Ember.run.scheduleOnce('afterRender', this, function () {
          (0, _dialog.default)('', ['OK'], Ember.$(this.element).find('.question-statistics-multiple-blank-stats').html(), 0);
        });
      });
      this.boot_area(Ember.$(this.element).find(`.question-stats-question-text`), true, true, true, false, true);
    }

    /**
     * /(?<=\[).+?(?=\])/g) - lookbehind doesn't work in Safari <= 16.3
     
    get blanksOrdered() {
      let ret = []
      let question_text = this.statistics.question_text
      for(const m of question_text.matchAll(/(?<=\[).+?(?=\])/g)) {
        ret.push(m[0])
      }
       return ret
    }*/

    getBlankPercentages() {
      let stats = {};
      let totalResponses = this.statistics.responses;
      for (const answerSet of this.statistics.answer_sets) {
        stats[answerSet.text] = {};
      }
      for (const answerSet of this.statistics.answer_sets) {
        let percentageCorrect = `0%`;
        let usernames = [];
        let answerResponses = 0;
        for (const answer of answerSet.answers) {
          if (answer.correct) {
            percentageCorrect = `${+parseFloat(answer.responses / totalResponses * 100).toFixed(2)}`;
            usernames.push(...answer.user_names);
          }
          if (answer.id != 'none') {
            answerResponses += answer.responses;
          }
        }
        stats[answerSet.text].correct = percentageCorrect;
        stats[answerSet.text].usernames = usernames;
        stats[answerSet.text].answered = +parseFloat(answerResponses / totalResponses * 100).toFixed(2);
      }
      return stats;
    }
    get blanks() {
      return this.statistics.answer_sets.map(as => as.text);
    }
    get questionText() {
      let text = this.statistics.question_text;
      this.statistics.answer_sets.forEach(as => {
        text = text.replace(`[${as.text}]`, `<span class="question-stats-placeholder">${as.text}</span>`);
      });
      return text;
    }
    changePlaceholder(placeholderId) {
      this.placeholderId = placeholderId;
      this.setAnswers();
    }
    showUsers(users) {
      let usersHtml = `<table class="question-stats-usernames">
                        <tbody>`;
      for (let user of users.sort()) {
        usersHtml += `<tr>
                       <td>${user}</td>
                     </tr>`;
      }
      usersHtml += '</tbody></table>';
      (0, _dialog.default)('', ['OK'], usersHtml, 0);
    }
    setAnswers() {
      let answerSet = this.statistics.answer_sets.find(as => as.text == this.placeholderId);
      if (answerSet) {
        this.answers = answerSet.answers;
      } else {
        this.answers = [];
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "placeholderId", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "answers", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "changePlaceholder", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "changePlaceholder"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showUsers", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "showUsers"), _class.prototype)), _class));
});