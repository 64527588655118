define("bocce/models/fill_in_multiple_blank", ["exports", "bocce/models/quiz_question", "@ember-data/model"], function (_exports, _quiz_question, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let FillInMultipleBlank = _exports.default = (_dec = Ember._tracked, (_class = class FillInMultipleBlank extends _quiz_question.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "default_answer", {});
      _defineProperty(this, "doCheckAnswer", true);
      _defineProperty(this, "doShowAnswer", true);
      _initializerDefineProperty(this, "_textFormatted", _descriptor, this);
    }
    isAnswerCorrect() {
      return super.isAnswerCorrect((given, correct) => {
        let isCorrect = true;
        for (const blankId in given) {
          let givenBlankAnswer = given[blankId];
          let correctBlankAnswers = correct[blankId];
          if (correctBlankAnswers == null || givenBlankAnswer == null) {
            return false;
          }
          isCorrect = isCorrect && correctBlankAnswers.map(c => c.toLowerCase()).includes(givenBlankAnswer.toLowerCase());
        }
        return isCorrect;
      });
    }
    addEventListeners() {
      if (!this.quizzes.hasScore) {
        this.blankIds.forEach(blankId => {
          $(`#question-${this.get('id')}-${blankId}`).off('input.fillInMultiBlanks').on('input.fillInMultiBlanks', event => {
            let givenAnswer = this.get('given_answer');
            if (event.target.value) {
              givenAnswer[blankId] = event.target.value;
            } else if (givenAnswer[blankId]) {
              delete givenAnswer[blankId];
            }
            Ember.set(this, 'given_answer', {
              ...givenAnswer
            });
          });
        });
      }
    }
    async onNextOrFinish() {
      if (this.quizzes.isTimedQuiz && this.answerUnsaved) {
        await this.updateCanvas();
      }
      this.answerUnsaved = false;
    }
    get textFormatted() {
      return null;
    }
    get blankIds() {
      let answers = this.get('answers');
      let ids = new Set();
      answers.forEach(answer => {
        ids.add(answer.blank_id);
      });
      return ids;
    }
    get formattedCorrectAnswer() {
      let retval = this.get('text');
      const answer = this.get('correct_answer');
      for (let id in answer) {
        retval = retval.replace(`[${id}]`, `[${answer[id]}]`);
      }
      retval = retval.replace(/(<([^>]+)>)/ig, '');
      return retval;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_textFormatted", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class));
});