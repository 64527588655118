define("bocce/components/rubric/grading", ["exports", "bocce/utilities/dialog", "lodash.isequal"], function (_exports, _dialog, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const RubricGrading2 = Ember.Component.extend({
    assessmentObserver: Ember.observer('submission.rubric_assessments.@each.{points}', 'lateDeductionAssessment.{points}', function () {
      const assessedPoints = this.assessedPoints;
      if (!assessedPoints) {
        this.set('grade', 0);
        return;
      }

      //Sanitizing
      this.data.forEach(d => {
        //Don't allow more points than the maximum.
        if (d.assessment.points > d.criterion.points) {
          d.assessment.set('points', d.criterion.points);
        } else if (d.assessment.points < 0) {
          //Don't allow negative points
          d.assessment.set('points', 0);
        }
      });

      //Late deduction points can't be more than the assessed points
      if (this.lateDeductionAssessment.get('points') > assessedPoints) {
        this.lateDeductionAssessment.set('points', assessedPoints);
      } else if (this.lateDeductionAssessment.get('points') < 0) {
        this.lateDeductionAssessment.set('points', 0);
      }
      this.set('grade', this.assessedPoints - (this.lateDeductionAssessment.get('points') || 0));
    }),
    //Keep track of whether the content is dirty.
    contentDirtyObserver: Ember.observer('submission.rubric_assessments.@each.{points,comments}', 'lateDeductionAssessment.{points}', function () {
      const assessment = {
        assessment: this.data.map(d => ({
          id: d.assessment.id,
          points: +d.assessment.points,
          comments: d.assessment.comments
        })),
        lateDeduction: +this.lateDeductionAssessment.get('points'),
        lateDeductionComment: this.lateDeductionAssessment.get('comments')
      };
      this.set('contentIsDirty', !(0, _lodash.default)(this.get('defaultAssessment'), assessment));
    })
  });
  let RubricGrading = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember.inject.service('rubric'), _dec5 = Ember._tracked, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service('userprofile'), _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = class RubricGrading extends RubricGrading2 {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "detailsShown", _descriptor, this);
      _initializerDefineProperty(this, "grade", _descriptor2, this);
      _initializerDefineProperty(this, "lateDeductionAssessment", _descriptor3, this);
      _initializerDefineProperty(this, "rubricService", _descriptor4, this);
      _initializerDefineProperty(this, "working", _descriptor5, this);
      _initializerDefineProperty(this, "store", _descriptor6, this);
      _initializerDefineProperty(this, "userprofileService", _descriptor7, this);
      _initializerDefineProperty(this, "initialized", _descriptor8, this);
      //Seemed like the simplest way to not call resetAssessment when exiting the grader upon a grade being given.
      _defineProperty(this, "ignoreResetAssessmentCall", false);
    }
    init() {
      super.init(...arguments);
      //Sets submission controller callbacks. First one is used to determine if the content is dirty. Second
      //one is used to reset the rubric assessment when the editor is destroyed.
      this.setRubricAssessmentIsDirtyCallback(() => {
        return this.rubricService.isGrading && this.get('contentIsDirty');
      });
      let assessments = [];
      this.get('criteria').forEach(criterion => {
        const assessmentId = `${this.get('submission.db_id')}_${this.get('submission.attempt')}_${criterion.id}`;
        let assessment = this.store.peekRecord('assessment', assessmentId);
        if (!assessment) {
          assessment = this.store.createRecord('assessment', {
            id: assessmentId,
            points: '',
            comments: ''
          });
        }
        assessments.push(assessment);
      });
      this.get('submission').set('rubric_assessments', assessments);
      let deductionAssessmentId = `${this.get('submission.db_id')}_${this.get('submission.attempt')}_-1`;
      //The late deduction criteria's id ends in '-1'
      let assessment = this.store.peekRecord('assessment', deductionAssessmentId);

      //Do we have a late deduction?
      if (assessment) {
        this.lateDeductionAssessment = assessment;
      } else {
        this.lateDeductionAssessment = this.store.createRecord('assessment', {
          id: deductionAssessmentId,
          points: 0,
          comments: ''
        });
      }

      //Used when exiting the grader and also for determining when content is dirty.
      this.set('defaultAssessment', {
        assessment: this.data.map(d => ({
          id: d.assessment.id,
          points: +d.assessment.points,
          comments: d.assessment.comments
        })),
        lateDeduction: +this.lateDeductionAssessment.get('points'),
        lateDeductionComment: this.lateDeductionAssessment.get('comments')
      });
    }
    didInsertElement() {
      //Necessary to trigger the observers
      this.set('submission.rubric_assessments', this.get('submission.rubric_assessments') || []);
    }
    willDestroyElement() {
      //This method is called both when exiting the submission modal and also when assigning a grade. We don't want to reset the assessment
      //when assigning a grade. So, this is why this logic is in place.
      if (!this.ignoreResetAssessmentCall) {
        this.resetAssessment();
      }
    }
    get data() {
      let ret = [];
      this.get('criteria').forEach(criterion => {
        let assessment = this.store.peekRecord('assessment', `${this.get('submission.db_id')}_${this.get('submission.attempt')}_${criterion.get('id')}`);
        ret.push({
          assessment,
          criterion
        });
      });
      return ret;
    }
    get assessedPoints() {
      let total = 0;

      /**
       * return null if nothing has any points. i.e.: 
       * this.data.some(d => !!+d.assessment.points) returns true if anything has points (+ converts to int, !! converts to boolean)
       * So, !this.data.some(d => !!+d.assessment.points) means that nothing has any points.
       */
      if (!this.data.some(d => !!+d.assessment.points)) {
        return null;
      }
      this.data.forEach(d => {
        if (+d.assessment.points) {
          total += +d.assessment.points;
        }
      });
      return total;
    }
    get percentage() {
      return Math.round(100 * this.get('grade') / this.get('submission.assignment.points_possible'));
    }
    resetAssessment() {
      const defaultAssessment = this.get('defaultAssessment');
      if (this.get('submission.rubric_assessments')) {
        this.get('submission.rubric_assessments').forEach(a => {
          const assessment = defaultAssessment.assessment.find(p => p.id == a.get('id'));
          if (assessment) {
            Ember.set(a, 'points', assessment.points);
            Ember.set(a, 'comments', assessment.comments);
          }
        });
      }
      this.set('lateDeductionAssessment.points', defaultAssessment.lateDeduction);
      this.set('lateDeductionAssessment.comments', defaultAssessment.lateDeductionComment);
    }
    onNoCredit() {
      this.data.forEach(d => {
        Ember.set(d.assessment, 'points', 0);
      });
      this.lateDeductionAssessment.set('points', 0);
      this.element.scrollIntoView(false);
    }
    submitGrade() {
      this.working = true;
      let hasAllCriteria = true;
      this.data.forEach(d => {
        if (d.assessment.points === '') {
          hasAllCriteria = false;
          return;
        }
      });
      if (!hasAllCriteria) {
        (0, _dialog.default)('Please make sure all criteria are given point values before submitting.').then(() => {
          $(this.element).find('.grading-rubric-grading-rubric-points').each(function () {
            if (!$(this).val()) {
              $(this).toggleClass("incomplete");
            }
          });
          $(this.element).find('.grading-rubric-grading-rubric-points').on('input', function () {
            if (!$(this).val()) {
              $(this).addClass("incomplete");
            } else {
              $(this).removeClass("incomplete");
            }
          });
        });
        this.working = false;
        return;
      }
      if (this.lateDeductionAssessment.get('points') === '') {
        this.lateDeduction = 0;
      }

      //If the late deduction amount was changed.
      if (this.get('defaultAssessment.lateDeduction') != this.lateDeductionAssessment.get('points') || this.get('defaultAssessment.lateDeductionComment') != this.lateDeductionAssessment.get('comments')) {
        const id = `${this.submission_id}_${this.attempt}_-1`;
        const r = this.store.peekRecord('assessment', id);

        //We don't store a late deduction with every assessment. If there is no late deduction
        //assessment, create one. If we do have one, update the points.
        if (!r) {
          const lateDeductionAssessment = this.store.createRecord('assessment', {
            id,
            points: this.lateDeductionAssessment.get('points'),
            comments: this.lateDeductionAssessment.get('comments')
          });
          this.get('submission.rubric_assessments').pushObject(lateDeductionAssessment);
        } else {
          r.set('points', this.lateDeductionAssessment.get('points'));
        }
      }
      this.ignoreResetAssessmentCall = true;
      this.onSubmitGrade(this.get('grade'), () => {
        //On complete
        Ember.run.scheduleOnce('afterRender', () => {
          this.rubricService.isGrading = false;
          this.working = false;
        });
      });
    }
    showGuide() {
      const pointsPossible = this.get('submission.assignment.points_possible');
      const range = Math.floor(pointsPossible * .10);
      const ratings = [{
        name: 'Excellent'
      }, {
        name: 'Good'
      }, {
        name: 'Fair'
      }, {
        name: 'Needs Improvement'
      }, {
        name: 'Fail'
      }];
      for (let i = 0; i < ratings.length; i++) {
        ratings[i].range = `(${pointsPossible - range * (i + 1) - i}-${pointsPossible - range * i - i})`;
      }
      let html = `<table><tbody>${ratings.map(r => `<tr><td>${r.name}</td><td>${r.range}</td>`).join('')}</tbody></table>`;
      (0, _dialog.default)(html);
    }
    async exitGrader() {
      let result = 'Exit Grader';
      if (this.get('contentIsDirty')) {
        result = await (0, _dialog.default)('', ['Go Back', 'Exit Grader'], `
        <div class="grading-rubric-grading-exit-message-container">
            <div class="grading-rubric-grading-exit-message-container-message1">Are you sure you want to exit the grader?</div>
            <div class="grading-rubric-grading-exit-message-container-message2">Your changes will not be saved.</div>
        </div>
    `, 0, ['grading-rubric-grading-exit']);
      }
      if (result == 'Exit Grader') {
        this.rubricService.isGrading = false;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "detailsShown", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "grade", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "lateDeductionAssessment", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "rubricService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "working", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "userprofileService", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "initialized", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onNoCredit", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onNoCredit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submitGrade", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "submitGrade"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showGuide", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "showGuide"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "exitGrader", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "exitGrader"), _class.prototype)), _class));
});