define("bocce/components/bookmarks/bookmark-note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let BookmarksHeaderComponent = _exports.default = (_dec = Ember._action, (_class = class BookmarksHeaderComponent extends Ember.Component {
    //This executes when a bookmark note is first rendered. It also executes when 
    //a bookmark note is modified via the UI, which ensures that the note's
    //'Show All' button is appropriately shown/hidden depending on 
    //the length of the modified note.
    didRender() {
      //This logic prevents an infinite loop caused by the below setting of
      //'noteClipped'.
      if (!this.bookmark.noteClipped) {
        const elem = this.element.querySelector('.bookmark-item-note');
        if (elem && elem.clientHeight < elem.scrollHeight) {
          Ember.set(this.bookmark, 'noteClipped', true);
        }
      }
    }
    setNotesMoreShowing(bookmark, moreShowing) {
      Ember.set(bookmark, 'notesMoreShowing', moreShowing);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "setNotesMoreShowing", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "setNotesMoreShowing"), _class.prototype)), _class));
});