define("bocce/services/rtc-rec", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let RtcRec = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, (_class = class RtcRec extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "kalturaUpload", _descriptor3, this);
      _initializerDefineProperty(this, "legacyAttachmentManager", _descriptor4, this);
      _defineProperty(this, "videoStatus", 'uninitiated');
      _initializerDefineProperty(this, "echoCancellationEnabled", _descriptor5, this);
      _initializerDefineProperty(this, "presetResolution", _descriptor6, this);
      _initializerDefineProperty(this, "videoInputDevices", _descriptor7, this);
      _initializerDefineProperty(this, "audioInputDevices", _descriptor8, this);
    }
    captureCamera(resolution, callback) {
      let vidSettings = {};
      let audioSettings = {};
      if (resolution === 'hd') {
        vidSettings = {
          width: {
            min: 1280
          },
          height: {
            min: 720
          }
        };
      }
      if (!(localStorage.getItem('bocceEchoCancellation') === 'true')) {
        audioSettings = {
          echoCancellation: false,
          mozAutoGainControl: false,
          mozNoiseSuppression: false,
          googEchoCancellation: false,
          googAutoGainControl: false,
          googNoiseSuppression: false,
          googHighpassFilter: false
        };
      }
      vidSettings.deviceId = this.cameraDeviceId;
      audioSettings.deviceId = this.audioInputId;
      navigator.mediaDevices.getUserMedia({
        audio: audioSettings,
        video: vidSettings
      }).then(function (camera) {
        callback(camera);
      }).catch(function (error) {
        console.log(error);
      });
    }
    stopRecordingCallback(context, blob) {
      /* eslint-disable-next-line ember/no-jquery */
      let video = $('.recorder-monitor')[0],
        recorder = window.recorder;

      // Forcibly assign webm mimetype to blob
      blob = blob.slice(0, blob.size, 'video/webm');
      this.set('blobURL', URL.createObjectURL(blob));
      window.MediaSource = window.MediaSource || window.WebKitMediaSource;
      let mediaSource = new MediaSource();
      mediaSource.addEventListener('sourceopen', event => {
        /* eslint-disable-next-line ember/no-jquery */
        let fileType = $.isSafari ? 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"' : 'video/webm; codecs="vorbis,vp8"';
        event.target.addSourceBuffer(fileType);
      }, false);

      // Set up video preview
      video.removeAttribute('autoplay');
      video.src = video.srcObject = null;
      video.muted = false;
      video.volume = 1;
      video.controls = true;
      video.src = this.blobURL;

      // Set up video backup
      /* eslint-disable-next-line ember/no-jquery */
      $('#video-backup-link').attr('href', this.blobURL);
      this.set('vidBlob', blob);
      recorder.camera.stop();
      recorder.clearRecordedData();
      recorder = null;
      window.recorder = null;
    }
    toggleRTCPanel() {
      this.set('videoStatus', 'uninitiated');
      this.set('presetResolution', false);

      /* eslint-disable-next-line ember/no-jquery */
      $('.recorder-monitor').addClass('hidden').attr('src', '');
      /* eslint-disable-next-line ember/no-jquery */
      $('.video-backup-rec').attr('href', '');

      // Clear out camera
      let recorder = window.recorder;
      if (recorder) {
        recorder.camera.stop();
        recorder.clearRecordedData();
        recorder = null;
        window.recorder = null;
      }
      navigator.mediaDevices.enumerateDevices().then(deviceInfos => {
        for (let i = 0; i !== deviceInfos.length; ++i) {
          let deviceInfo = deviceInfos[i];
          let option = {};
          option.value = deviceInfo.deviceId;
          if (deviceInfo.kind === 'audioinput') {
            option.text = deviceInfo.label || 'Microphone ' + (audioInputSelect.length + 1);
            this.set('audioInputDevices', [...this.audioInputDevices, option]);
            if (!this.audioInputId) {
              this.set('audioInputId', deviceInfo.deviceId);
            }
          } else if (deviceInfo.kind === 'videoinput') {
            option.text = deviceInfo.label || 'Camera ' + (videoSelect.length + 1);
            if (!this.cameraDeviceId) {
              this.set('cameraDeviceId', deviceInfo.deviceId);
            }
            this.set('videoInputDevices', [...this.videoInputDevices, option]);
          }
        }
      });
      this.legacyAttachmentManager.audioRecPanelIsActive = false;
      this.set('cameraContainerActive', true);
    }
    changeAudioInput(id) {
      this.set('audioInputId', id);
    }
    changeVideoInput(id) {
      this.set('cameraDeviceId', id);
    }
    enableCamera(resolution) {
      let presetResolution = this.presetResolution;
      if (presetResolution) {
        resolution = presetResolution;
      } else {
        this.set('presetResolution', resolution);
      }
      this.captureCamera(resolution, camera => {
        /* eslint-disable-next-line ember/no-jquery */
        let fileType = $.isSafari ? 'video/mp4' : 'video/webm';
        /* eslint-disable-next-line ember/no-jquery */
        let video = $('.recorder-monitor')[0];
        video.setAttribute('autoplay', '');
        video.classList.remove('hidden');
        video.muted = true;
        video.volume = 0;
        video.removeAttribute('controls');
        video.srcObject = camera;
        window.recorder = new MediaStreamRecorder(camera, {
          mimeType: fileType
        });
        this.set('videoStatus', 'ready');
        this.set('recordingCurrentTime', '00:00');

        // release camera on stopRecording
        window.recorder.camera = camera;
      });
    }
    startVideoRec() {
      let recorder = window.recorder;
      if (recorder) {
        this.set('videoStatus', 'recording');
        recorder.record();
      } else {
        debug('No recorder enabled. Refresh browser and try again');
      }
      this.set('recordingStartTime', moment().unix());
      window.kalturaRec = setInterval(() => {
        let timeCalc = moment().unix() - this.recordingStartTime;
        this.set('recordingCurrentTime', moment(timeCalc * 1000).format('mm:ss'));
      }, 1000);
    }
    stopVideoRec() {
      let recorder = window.recorder;
      if (recorder) {
        this.set('videoStatus', 'recorded');
        clearInterval(window.kalturaRec);
        this.set('recordingStartTime', false);
        this.set('recordingCurrentTime', '00:00');
        recorder.stop(blob => {
          this.stopRecordingCallback(this, blob);
        });
      } else {
        debug('No recorder enabled. Refresh browser and try again');
      }
    }
    attachVideoRec() {
      this.set('videoStatus', 'uploading');
      this.set('uploadPercentage', '0%');
      this.kalturaUpload.kalturaUploadVideo(this.vidBlob, message => {
        window.alert(message);
        this.set('videoStatus', 'recorded');
      }, false, this.blobURL);
    }
    displayVideoBackup(close) {
      this.set('showVideoBackup', !close);
    }
    echoCancellationToggleVideo() {
      var echoCancellation = localStorage.getItem('bocceEchoCancellation') === 'true';
      if (echoCancellation) {
        localStorage.setItem('bocceEchoCancellation', false);
        this.set('echoCancellationEnabled', false);
      } else {
        localStorage.setItem('bocceEchoCancellation', true);
        this.set('echoCancellationEnabled', true);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "kalturaUpload", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "legacyAttachmentManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "echoCancellationEnabled", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return localStorage.getItem('bocceEchoCancellation') === 'true';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "presetResolution", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "videoInputDevices", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "audioInputDevices", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleRTCPanel", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "toggleRTCPanel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeAudioInput", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "changeAudioInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeVideoInput", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "changeVideoInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "enableCamera", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "enableCamera"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startVideoRec", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "startVideoRec"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "stopVideoRec", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "stopVideoRec"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "attachVideoRec", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "attachVideoRec"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displayVideoBackup", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "displayVideoBackup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "echoCancellationToggleVideo", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "echoCancellationToggleVideo"), _class.prototype)), _class));
});