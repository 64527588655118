define("bocce/components/quiz-questions/question", ["exports", "bocce/utilities/dialog", "lodash.isequal", "bocce/utilities/promise-queue", "bocce/mixins/boot"], function (_exports, _dialog, _lodash, _promiseQueue, _boot) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const Question2 = Ember.Component.extend(_boot.default, {
    givenAnswerObserver: Ember.observer('question.given_answer', async function () {
      this.hasAnswerChanged = true;
      /** This is using PromiseQueue because the onAnswerChange call needs to happen
       *  right away and it also needs to happen before the user is potentially told
       *  that time is up and thus before the quiz is submit (time-is-up dialog also uses PromiseQueue).
       */
      await _promiseQueue.default.enqueue(async () => {
        if (!(0, _lodash.default)(this.question.given_answer, this.question.default_answer)) {
          await this.question.onAnswerChange();
        }
      });
    })
  });
  let Question = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class Question extends Question2 {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "showingCorrectAnswer", _descriptor, this);
      _initializerDefineProperty(this, "showingAnswerCorrectFeedback", _descriptor2, this);
      _initializerDefineProperty(this, "currentAnswer", _descriptor3, this);
      _initializerDefineProperty(this, "questionInitialized", _descriptor4, this);
      _initializerDefineProperty(this, "hasAnswerChanged", _descriptor5, this);
      _defineProperty(this, "gotAnswerCorrect", false);
      _defineProperty(this, "numAttempts", 0);
    }
    init(...args) {
      super.init(...args);
      this.questionIndexPostfix = this.quizzes.questionIndexPostfix;
      if (!this.quizzes.hasScore) {
        this.quizzes.addNextQuestionCallback(async () => {
          if (!(0, _lodash.default)(this.question.given_answer, this.question.default_answer)) {
            await this.question.onNextOrFinish();
          }
        });
      }
      this.questionInitialized = true;
    }
    didInsertElement() {
      if (this.quizzes.performedInitialRender) {
        let node = Ember.$(this.element).find('.bootable-area');
        if (node && node.length) {
          // Boot each bootable-area node found in this component
          let promises = [];
          /* eslint-disable-next-line ember/no-jquery */
          node.each((i, n) => promises.push(this.boot_area(Ember.$(n), false, true, false, false, true)));
          Promise.all(promises);
        }
      }
    }
    get showCheckAnswerBtn() {
      return this.question.doCheckAnswer && this.notGradedAndNoScore();
    }
    get showShowAnswerBtn() {
      return this.question.doShowAnswer && this.notGradedAndNoScore();
    }
    get showNextBtn() {
      return this.active && this.quiz.presentation == 'single' && this.index < this.quiz.quiz_questions.length - 1;
    }
    get hasAnswer() {
      return this.question.hasAnswer;
    }
    get answerNeeded() {
      return this.question.answerNeeded;
    }
    notGradedAndNoScore() {
      let score = null;
      if (this.quiz.quiz_attempt) {
        score = this.quiz.quiz_attempt.score;
      }
      return !this.quiz.is_graded && score == null;
    }
    setShowingAnswerCorrectFeedback(showing) {
      this.showingAnswerCorrectFeedback = showing;
      if (showing) {
        this.numAttempts = this.numAttempts + 1;
      }
    }
    setShowingCorrectAnswer(showing) {
      let numAttemptsLeft = 3 - this.numAttempts;
      if (this.question.isAnswerCorrect()) {
        this.gotAnswerCorrect = true;
      }
      if (numAttemptsLeft > 0 && !this.gotAnswerCorrect) {
        (0, _dialog.default)(`Please check your answer ${numAttemptsLeft} more time${numAttemptsLeft > 1 ? 's' : ''}.`);
      } else {
        this.showingCorrectAnswer = showing;
      }
    }
    nextQuestion() {
      this.quizzes.nextQuestion(this.index + 1);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "showingCorrectAnswer", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "showingAnswerCorrectFeedback", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentAnswer", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "questionInitialized", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "hasAnswerChanged", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setShowingAnswerCorrectFeedback", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setShowingAnswerCorrectFeedback"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setShowingCorrectAnswer", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setShowingCorrectAnswer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "nextQuestion", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "nextQuestion"), _class.prototype)), _class));
  ;
});