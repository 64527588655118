define("bocce/models/submission", ["exports", "@ember-data/model", "bocce/mixins/attachments-mixin"], function (_exports, _model, _attachmentsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/models/submission.js
  /* eslint-disable-next-line ember/no-mixins */
  var _default = _exports.default = _model.default.extend(_attachmentsMixin.default, {
    db_id: (0, _model.attr)('number'),
    attempt: (0, _model.attr)('number'),
    assignment: (0, _model.belongsTo)('assignment', {
      async: true
    }),
    archive_body: (0, _model.attr)(),
    body: (0, _model.attr)('string'),
    submission_type: (0, _model.attr)('string'),
    // These are IDs for attachment objects, but they don't have pointers back to
    // the submission so a hasMany doesn't work.
    file_ids: (0, _model.attr)(),
    user: (0, _model.belongsTo)('user'),
    comments: (0, _model.hasMany)('comment', {
      async: true
    }),
    grade: (0, _model.attr)(),
    score: (0, _model.attr)(),
    late: (0, _model.attr)(),
    grade_matches_current_submission: (0, _model.attr)(),
    attachments: (0, _model.attr)(),
    date: (0, _model.attr)('date'),
    submitted_at: (0, _model.attr)('date'),
    posted_grade: (0, _model.attr)(),
    workflow_state: (0, _model.attr)(),
    teacher_requires_resubmission: (0, _model.attr)(),
    hidden_from_students: (0, _model.attr)('boolean'),
    submission_history: (0, _model.attr)(),
    is_updated: (0, _model.attr)('boolean'),
    showcase: (0, _model.attr)(),
    read: (0, _model.attr)('boolean'),
    rubric_assessments: (0, _model.hasMany)('assessment', {
      async: false
    }),
    rubric_assessment_objects: (0, _model.attr)(),
    percentage: Ember.computed('score', 'assignment.points_possible', function () {
      /* eslint-disable-next-line ember/no-get */
      return Math.round(100 * this.get('score') / this.get('assignment.points_possible'));
    }),
    my_submission: Ember.computed.reads('user.isCurrentUser'),
    needsGrade: Ember.computed('workflow_state', 'grade', 'teacher_requires_resubmission', function () {
      let state = this.workflow_state;
      return state === 'submitted' && this.teacher_requires_resubmission !== 'needs_resubmit' && !!this.get('assignment.due_at') || state === 'graded' && !this.grade;
    }),
    needsStudentAttention: Ember.computed('read', 'my_submission', 'teacher_requires_resubmission', function () {
      return /* //JRW: commenting out student read status// ! this.get('read') || */(
        this.my_submission && this.teacher_requires_resubmission === 'needs_resubmit'
      );
    }),
    needsTeacherAttention: Ember.computed('needsGrade', 'read', function () {
      return this.needsGrade || !this.read;
    }),
    daysLate: Ember.computed('date', 'assignment.due_at', function () {
      let timeSubmitted = moment(this.date);
      let timeDue = moment(this.get('assignment.due_at'));
      if (timeSubmitted.isAfter(timeDue)) {
        const difference = moment.duration(timeSubmitted.diff(timeDue));
        return difference.humanize();
      } else {
        return null;
      }
    })
  });
});