define("bocce/services/userprofile", ["exports", "bocce/utilities/dialog"], function (_exports, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let UserProfileService = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class UserProfileService extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "activeUser", _descriptor, this);
      _initializerDefineProperty(this, "isEditing", _descriptor2, this);
      _initializerDefineProperty(this, "notesTemp", _descriptor3, this);
      _initializerDefineProperty(this, "session", _descriptor4, this);
      _initializerDefineProperty(this, "store", _descriptor5, this);
    }
    editNote() {
      this.isEditing = true;
      this.notesTemp = this.notes;
    }
    setIsEditing(isEditing) {
      this.isEditing = isEditing;
    }
    modifyNotes() {
      const userId = this.activeUser.content.id;
      Ember.$.ajax({
        type: 'POST',
        url: '/interface/user_notes/',
        data: JSON.stringify({
          creator_user_id: this.session.user.get('id'),
          target_user_id: userId,
          notes: this.notesTemp
        }),
        success: () => {
          const user = this.session.get('user');
          const notes = user.get('notes');
          notes[userId] = this.notesTemp;
          user.set('notes', notes);
          this.isEditing = false;
        },
        error: e => {
          (0, _dialog.default)("An error occurred modifying the note. Please try again later.");
          this.isEditing = false;
        }
      });
    }
    toggleUserProfile(id) {
      var posX = window.event ? window.event.pageX : window.mouseX,
        posY = window.event ? window.event.pageY : window.mouseY,
        userId = parseInt(id),
        //Use this ID to fetch other user info
        /* eslint-disable-next-line ember/no-jquery */
        $uPro = Ember.$('.user-profile');
      this.activeUser = this.store.findRecord('user', userId);
      if (this.session.get('isInstructor')) {
        const notes = this.session.user.get('notes');
        const users = Object.keys(notes);

        //We might go inside here if the student is from another section. Might happen if loading a 
        //student profile from the inbox tab.
        if (!users.includes(id)) {
          Ember.$.ajax({
            type: 'GET',
            url: `/interface/user_notes/${userId}/single`,
            success: async notesResult => {
              notes[userId] = notesResult;

              //creating a new notes object triggers ember to recalculate the 'notes' property and thus a re-render
              //of that property on the template.
              this.session.user.set('notes', {
                ...notes
              });
            }
          });
        }
      }
      if (window.innerHeight < $uPro.height() + posY) {
        posY = window.innerHeight - $uPro.height() - 44;
      }
      if (window.innerWidth < $uPro.width() + posX) {
        posX = window.innerWidth - $uPro.width() - 44;
      }

      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.user-profile').addClass('active floating-modal');
      /* eslint-disable-next-line ember/no-jquery */
    }
    get notes() {
      const notes = this.session.get('user.notes');
      if (this.activeUser && notes) {
        const userId = this.activeUser.content.id;
        return userId ? notes[userId] : '';
      } else {
        return '';
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "activeUser", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isEditing", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notesTemp", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "editNote", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "editNote"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setIsEditing", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setIsEditing"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "modifyNotes", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "modifyNotes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleUserProfile", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "toggleUserProfile"), _class.prototype)), _class));
});