define("bocce/components/submission/footer-interact", ["exports", "bocce/mixins/editable"], function (_exports, _editable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_editable.default, {
    actions: {
      postSubmissionComment() {
        //If we were to directly call the passed in postSubmissionComment function from the template,
        //Ember would include a pointer event. We don't want that, which is why we are calling it through 
        //an action.
        this.postSubmissionComment();
      }
    }
  });
});