define("bocce/components/classroom/lessons/admin/quizzes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let DeleteLastAttempt = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class DeleteLastAttempt extends Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "selectedUser", _descriptor, this);
      _initializerDefineProperty(this, "selectedQuiz", _descriptor2, this);
      _initializerDefineProperty(this, "session", _descriptor3, this);
    }
    init(...args) {
      super.init(...args);

      //I don't think this is used any more. But, right now I'm too nervous to touch anything.
      this.set('operations', ['quiz-admin/unlock-for-next-attempt', 'quiz-admin/delete-latest-attempt']);
    }
    async loadQuizUsers(quizId) {
      let path = `/interface/sections/${this.get('session.section.id')}/quizzes/${quizId}/submission_users`,
        data = await $.get(path);
      this.set('quizUsers', data.quiz_submission_users.users);
    }
    selectQuiz(quizId) {
      this.set('selectedQuizId', quizId);
      this.loadQuizUsers(quizId);
      let thisQuiz = this.store.findRecord('quiz', quizId);
      this.selectedQuiz = thisQuiz;
      this.selectedUser = null;
    }
    async expandOperations(userId) {
      let user = await this.store.findRecord('user', userId);
      user.set('quizAttemptMade', this.get('quizUsers').find(u => u.id == userId).attempt_made);
      this.selectedUser = user;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selectedUser", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selectedQuiz", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "selectQuiz", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "selectQuiz"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "expandOperations", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "expandOperations"), _class.prototype)), _class));
  ;
});