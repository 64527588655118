define("bocce/services/gainsight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let GainsightService = _exports.default = (_dec = Ember.inject.service, (_class = class GainsightService extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "session", _descriptor, this);
    }
    init(...args) {
      super.init(...args);
      this.environment = this.session.environmentProcessed.name;
      this.loginVar = "AP-T74YMJOTJS2Y-2-3"; // this is the Gainsight login var for non-production and non-main k8s environments.

      if (this.environment === 'production') {
        this.loginVar = "AP-T74YMJOTJS2Y-2"; // this is the Gainsight login var for production environment.
      } else if (this.environment === 'main') {
        this.loginVar = "AP-T74YMJOTJS2Y-2-2"; // this is the Gainsight login var for the main k8s environment.
      }
      this.px_tag = function (n, t, a, e, co) {
        var i = "aptrinsic";
        n[i] = n[i] || function () {
          (n[i].q = n[i].q || []).push(arguments);
        }, n[i].p = e;
        n[i].c = co;
        var r = t.createElement("script");
        r.async = !0, r.src = a + "?a=" + e;
        var c = t.getElementsByTagName("script")[0];
        c.parentNode.insertBefore(r, c);
      }.bind(this, window, document, "https://web-sdk.aptrinsic.com/api/aptrinsic.js", this.loginVar);
      function pxPageTimer(maxSecondsTracked, trackPagesOverMax) {
        this.pageName = null;
        this.startTime = null;
        this.maxSecondsTracked = maxSecondsTracked || 3600 * 24;
        this.trackPagesOverMax = trackPagesOverMax !== false;
        this.startTimer = function (pageName) {
          try {
            if (this.pageName && this.pageName !== pageName) {
              this.endTimer();
            }
            this.pageName = pageName;
            this.startTime = Date.now();
          } catch (e) {
            console.log("Unable to start timer on ", this.pageName);
          }
        };
        this.endTimer = function (pageUnloaded) {
          try {
            if (this.pageName) {
              let endTime = Date.now();
              let secondsOnPage = (endTime - this.startTime) / 1000;
              if (secondsOnPage <= this.maxSecondsTracked || this.trackPagesOverMax) {
                window.aptrinsic('track', 'timeOnPage', {
                  'pageName': this.pageName,
                  'seconds': Math.min(secondsOnPage, this.maxSecondsTracked),
                  'pageUnloaded': pageUnloaded,
                  "eventDate": moment(endTime).tz('America/New_York').format('YYYY/MM/DD')
                });
              }
              this.pageName = undefined;
            }
          } catch (e) {
            console.log(`Unable to log time on page ${this.pageName}. Error:`, e);
          }
        };
      }
      (function InitializeTimer() {
        let maxSecondsTracked = 60 * 60; // Track time on page for up to one hour
        let trackPagesOverMax = false; // Ignore any pages over one hour
        let featureTimer = new pxPageTimer(maxSecondsTracked, trackPagesOverMax);

        // Initial page
        featureTimer.startTimer(window.location.href);
        window.addEventListener('hashchange', () => {
          featureTimer.startTimer(window.location.href);
        });
        window.addEventListener('popstate', () => {
          featureTimer.startTimer(window.location.href);
        });
        window.addEventListener('beforeunload', () => {
          featureTimer.endTimer(true);
        });
        let realPushState = window.history.pushState;
        window.history.pushState = function (state, title, newLocation) {
          featureTimer.startTimer(newLocation.href);
          return realPushState.apply(window.history, arguments); // Call the original
        };
      })();
    }
    renderGlobalContextGainsightPxTag(now) {
      // Set the Gainsight Global Context for courses

      // Begin GainSight PX Tag
      this.px_tag();
      // End GainSight PX Tag

      let payload = {
        "globalContext_date": moment(now).tz('America/New_York').format('YYYY/MM/DD HH:mm:ss') + ' ET'
      };

      // If the user is not in a course (currently, lobby is the only exception), the payload will only include the date.
      if (this.session.course) {
        payload["title"] = this.session.course.title;
        payload["termName"] = this.session.course.term.name;
        payload["courseCode"] = this.session.course.code.split(".")[0];
        payload["courseSection"] = this.session.course.code.split(".")[1];
      }
      if (this.session.environmentProcessed.name !== 'production') {
        console.log(`Gainsight/Aptrinsic payload (renderGlobalContextGainsightPxTag)`, payload);
      }
      aptrinsic('set', 'globalContext', payload);
    }
    renderIdentifyGainsightPxTag(now) {
      let full_name = this.session.user.get('sortable_name');
      if (!full_name || full_name.split(',').length < 2) {
        full_name = 'not found, not found';
      }

      // Begin GainSight PX Tag
      this.px_tag();
      // End GainSight PX Tag

      let payload_1 = {
          //User Fields
          "identify_date": moment(now).tz('America/New_York').format('YYYY/MM/DD HH:mm:ss') + ' ET',
          "id": this.session.user.get('acsUserId'),
          // ACS User ID
          "sfdcContactId": this.session.user.get('sfUserId'),
          // Salesforce ID
          "email": this.session.user.get('email'),
          "firstName": full_name.split(',')[1].trim(),
          "lastName": full_name.split(',')[0].trim()
        },
        payload_2 = {
          //Account Fields
          "id": this.session.user.get('acsUserId') // ACS User ID
        };
      if (this.session.environmentProcessed.name !== 'production') {
        console.log(`Gainsight/Aptrinsic payload_1 & payload_2 (renderIdentifyGainsightPxTag)`, payload_1, payload_2);
      }
      aptrinsic("identify", payload_1, payload_2);
    }
    renderDiscussionGainsightPxTag(now) {
      // Begin GainSight PX Tag
      this.px_tag();
      // End GainSight PX Tag

      let payload = {
        "discussion_date": moment(now).tz('America/New_York').format('YYYY/MM/DD HH:mm:ss') + ' ET',
        "title": this.session.course.title,
        "termName": this.session.course.term.name,
        "courseCode": this.session.course.code.split(".")[0],
        "courseSection": this.session.course.code.split(".")[1],
        "instructors": this.getInstructors()
      };
      if (this.session.environmentProcessed.name !== 'production') {
        console.log(`Gainsight/Aptrinsic payload (renderDiscussionGainsightPxTag)`, payload);
      }
      aptrinsic('track', 'Discussion', payload);
    }
    renderChatGainsightPxTag(now) {
      // Begin GainSight PX Tag
      this.px_tag();
      // End GainSight PX Tag

      let payload = {
        "chat_date": moment(now).tz('America/New_York').format('YYYY/MM/DD HH:mm:ss') + ' ET',
        "title": this.session.course.title,
        "termName": this.session.course.term.name,
        "courseCode": this.session.course.code.split(".")[0],
        "courseSection": this.session.course.code.split(".")[1],
        "instructors": this.getInstructors()
      };
      if (this.session.environmentProcessed.name !== 'production') {
        console.log(`Gainsight/Aptrinsic payload (renderChatGainsightPxTag)`, payload);
      }
      aptrinsic('track', 'Chat', payload);
    }
    async renderWorkGainsightPxTag(user_id, course_id, type, now) {
      let payload = {
        "work_date": moment(now).tz('America/New_York').format('YYYY/MM/DD HH:mm:ss') + ' ET',
        "type": type,
        "title": this.session.course.title,
        "termName": this.session.course.term.name,
        "courseCode": this.session.course.code.split(".")[0],
        "courseSection": this.session.course.code.split(".")[1],
        "instructors": this.getInstructors()
      };
      const percentOntimeWork = (await Ember.$.get(`/interface/system/percent_on_time_work/${user_id}/${course_id}/${type}/${this.session.environmentProcessed.name}`)) || {};
      for (let i in percentOntimeWork) {
        payload[i] = percentOntimeWork[i];
      }
      if (this.session.environmentProcessed.name !== 'production') {
        console.log(`Gainsight/Aptrinsic payload (renderWorkGainsightPxTag)`, payload);
      }
      aptrinsic('track', 'Work', payload);
    }
    getInstructors() {
      let instructors = [];
      const instr = this.get('session.section.teachers').map(t => ({
        name: t.name
      }));
      for (let i of instr) {
        instructors.push(i.name);
      }
      return instructors.join(", ");
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});