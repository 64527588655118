define("bocce/models/file_upload", ["exports", "bocce/models/quiz_question", "bocce/helpers/upload"], function (_exports, _quiz_question, _upload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let FileUpload = _exports.default = (_dec = Ember.inject.service, (_class = class FileUpload extends _quiz_question.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _defineProperty(this, "default_answer", 0);
    }
    async onNextOrFinish() {
      if (this.quizzes.isTimedQuiz && this.answerUnsaved) {
        this.quizzes.uploadingQuestion = true;
        await this.setGivenAnswer();
        await this.updateCanvas();
      }
      this.answerUnsaved = false;
    }
    async setGivenAnswer() {
      const answerFileId = await this.getAnswerFileId();
      if (answerFileId) {
        this.set('given_answer', answerFileId);
      }
    }
    async getAnswer() {
      if (this.quizzes.isTimedQuiz) {
        return this.given_answer;
      } else {
        const answerFileId = await this.getAnswerFileId();
        return answerFileId ?? this.default_answer;
      }
    }
    async getAnswerFileId() {
      try {
        const file = this.get('given_answer');
        if (file) {
          return await (0, _upload.default)(file, this.session.get('user.id'), this.quizzes.store, 'quizzes', false, null, `/api/v1/courses/${this.session.get('course.id')}/quizzes/${this.quizzes.quiz.search_id}/submissions/self/files`);
        } else {
          return null;
        }
      } catch (e) {
        return null;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  ;
});