define("bocce/routes/classroom/lessons/submission", ["exports", "bocce/routes/classroom/lessons/modal-base", "showdown"], function (_exports, _modalBase, _showdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalBase.default.extend({
    // submissions are nested in assignments; this makes sure that we have
    // the necessary assignment data and api nesting setup before the
    // model hook gets the submission.
    beforeModel() {
      this._super.apply(this, arguments);
      this.store.nestResources('assignment', [{
        section: this.session.get('section.id')
      }]);
      return this.store.findAll('assignment', {
        reload: true
      });
    },
    // Once the submission is loaded, set up nesting so requests for
    // submissions to through this assignment
    afterModel(model) {
      this._super.apply(this, arguments);
      this.store.nestResources('submission', [{
        section: model.get('assignment.section.id')
      }, {
        assignment: model.get('assignment.id')
      }]);
    },
    // NK todo: get rid of this in favor of renaming 'openAssignmentView'
    // to 'model' on the controller. that way we can use the default hook.
    setupController(controller, model) {
      controller.set('openAssignment', false);
      controller.set('openAssignmentView', model);
      const convertor = new _showdown.default.Converter();
      model.get('assignment').then(a => {
        const rc = a.get('rubric_criteria');
        if (rc) {
          rc.forEach(c => {
            c.set('description', convertor.makeHtml(c.get('description')));
          });
          controller.set('rubric_criteria', rc);
        }
      });

      /* eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions */
      Ember.run.scheduleOnce('afterRender', this, () => {
        controller.bootContent();
      });
    }
  });
});