define("bocce/templates/components/rubric/rubric", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "qctfd3C6",
    "block": "{\"symbols\":[\"criterion\"],\"statements\":[[6,[37,2],[[32,0,[\"criteria\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"table\"],[14,0,\"grading-rubric-rubric\"],[12],[2,\"\\n        \"],[10,\"thead\"],[12],[2,\"\\n            \"],[10,\"tr\"],[12],[2,\"\\n                \"],[10,\"th\"],[14,0,\"grading-rubric-rubric-criteria-copy\"],[12],[2,\"Criteria\"],[13],[2,\"\\n                \"],[10,\"th\"],[14,0,\"grading-rubric-rubric-possible-points-copy\"],[12],[2,\"Possible Points\"],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"data\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"tr\"],[12],[2,\"\\n                    \"],[10,\"td\"],[14,0,\"grading-rubric-rubric-criterion-description-container\"],[12],[2,\"\\n                        \"],[10,\"div\"],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n                        \"],[10,\"div\"],[14,0,\"grading-rubric-rubric-criterion-description\"],[12],[2,[32,1,[\"description\"]]],[13],[2,\"\\n                    \"],[13],[2,\"\\n                    \"],[10,\"td\"],[14,0,\"grading-rubric-rubric-criterion-points\"],[12],[1,[32,1,[\"points\"]]],[13],[2,\"\\n                \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/rubric/rubric.hbs"
    }
  });
});