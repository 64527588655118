define("bocce/services/events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let EventsService = _exports.default = (_dec = Ember.inject.service, (_class = class EventsService extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "session", _descriptor, this);
    }
    getOtherSectionChats(classroomController, title, startAt) {
      let sectionCodes = classroomController.get('userSectionCodes') || [],
        currentTermEnrollments = sectionCodes.filter(sectionCode => {
          /* eslint-disable-next-line ember/no-get */
          return sectionCode.termLabel === this.session.course.term.name && sectionCode.courseId !== this.session.course.id;
        }),
        promises = [],
        events = [];
      currentTermEnrollments.forEach(currentTermEnrollment => {
        let courseId = currentTermEnrollment.courseId;
        /* eslint-disable-next-line ember/no-jquery */
        promises.push($.get(`/interface/courses/${courseId}/events`).then(results => {
          let otherSectionChats = results.event,
            filteredOtherSectionChats = [];
          if (otherSectionChats) {
            filteredOtherSectionChats = otherSectionChats.filter(otherSectionChat => {
              /* eslint-disable-next-line ember/no-get */
              return otherSectionChat.title === title && otherSectionChat.startAt === startAt;
            });
          }
          for (let i = 0; i < filteredOtherSectionChats.length; i++) {
            filteredOtherSectionChats[i]['sectionId'] = currentTermEnrollment.sectionId;
            filteredOtherSectionChats[i]['courseTitle'] = currentTermEnrollment.courseTitle;
            filteredOtherSectionChats[i]['code'] = currentTermEnrollment.code;
            filteredOtherSectionChats[i]['sectionLabel'] = currentTermEnrollment.sectionLabel;
          }
          events = events.concat(filteredOtherSectionChats);
        }));
      });
      return Promise.all(promises).then(_ => events);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  ;
});