define("bocce/controllers/classroom/lessons/event", ["exports", "bocce/config/environment", "bocce/mixins/enmodal", "bocce/mixins/editable", "bocce/mixins/webex", "bocce/mixins/nested-resources", "bocce/utilities/dialog", "sanitize-html"], function (_exports, _environment, _enmodal, _editable, _webex, _nestedResources, _dialog, _sanitizeHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-observers */

  function sanitize(string) {
    const sanitizeHtmlOptions = {
      // options for the sanitize-html package. see docs:
      // https://www.npmjs.com/package/sanitize-html#htmlparser2-options
      allowedTags: _sanitizeHtml.default.defaults.allowedTags.concat(['h2', 'u']),
      transformTags: {
        'h1': 'h2',
        // Word's header is h1, but our header is h2
        'a': function (tagName, attribs) {
          // filter out useless links because apparently the geniuses
          // at Microsoft decided to start including THOSE now
          if (!attribs['href'] || attribs['href'] === '') {
            return false;
          } else {
            return {
              tagName: tagName,
              attribs: {
                href: attribs.href
              }
            };
          }
        }
      }
    };
    return (0, _sanitizeHtml.default)(string, sanitizeHtmlOptions);
  }
  var _default = _exports.default = Ember.Controller.extend(_enmodal.default, _editable.default, _webex.default, _nestedResources.default, {
    classroom: Ember.inject.controller(),
    eventsService: Ember.inject.service('events'),
    userprofileService: Ember.inject.service('userprofile'),
    discussions: Ember.inject.controller(),
    event: Ember.inject.controller('classroom.lessons.event'),
    // required by WebexMixin
    events: Ember.inject.controller(),
    // required by WebexMixin
    newStudentEvent: Ember.inject.controller('classroom.lessons.student-event-new'),
    // required by WebexMixin

    queryParams: ['start_chat', 'join_chat', 'backup_code'],
    start_chat: false,
    join_chat: false,
    backup_code: false,
    hasOtherSectionChats: false,
    multiChatConnectionStatus: '',
    waitingForOtherSectionChats: true,
    sendCancelNotification: false,
    cancelRepeating: false,
    cancelAllSections: false,
    cancelEventConfirmedVisible: false,
    notEditingEvent: true,
    notifyStudents: true,
    tempEventStartAt: '',
    /**initialized in setupController */
    tempEventTitle: '',
    /**initialized in setupController */
    optionalEmailNote: '',
    isEventSaving: false,
    description: Ember.computed('model.description', function () {
      /* eslint-disable-next-line ember/no-get */
      let desc = this.get('model.description');
      if (desc && desc.indexOf('The instructor has started a live class!') === 0) {
        return '';
      }
      return desc;
    }),
    eventNotUpdated: Ember.computed('tempEventStartAt', 'tempEventTitle', 'bodyInput', function () {
      const tempStartAt = this.get('tempEventStartAt');
      const tempEventTitle = this.get('tempEventTitle');
      const bodyInput = this.get('bodyInput');
      return !tempStartAt || !tempEventTitle || !bodyInput || tempStartAt == this.model.get('startAt') && tempEventTitle == this.model.get('title') && bodyInput == this.model.get('description');
    }),
    future: Ember.computed('model.startAt', 'session.time', function () {
      /* eslint-disable-next-line ember/no-get */
      return moment() < moment(this.get('model.startAt'));
    }),
    past: Ember.computed('model.startAt', function () {
      /* eslint-disable-next-line ember/no-get */
      return moment() >= moment(this.get('model.startAt'));
    }),
    archived: Ember.computed('model.endAt', 'session.time', function () {
      /* eslint-disable-next-line ember/no-get */
      return moment() > moment(this.get('model.endAt'));
    }),
    // Hotfix
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    isMeetingSupposedToBeHappeningRightNow: Ember.computed('model.startAt', 'session.time', function () {
      let now = moment();

      /* eslint-disable-next-line ember/no-get */
      return moment(this.get('model.startAt')) <= now && /* eslint-disable-next-line ember/no-get */
      moment(this.get('model.endAt')) >= now;
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    runnable: Ember.computed('model.startAt', 'session.time', function () {
      let now = moment();

      /* eslint-disable-next-line ember/no-get */
      return moment(this.get('model.startAt')) <= now + 10 * 60 * 1000 && /* eslint-disable-next-line ember/no-get */
      moment(this.get('model.endAt')) >= now;
    }),
    userIsAdminOrStaff: Ember.computed('session', function () {
      return this.get('session.isAdmin') || this.get('session.isStaff') ? true : false;
    }),
    resetTempEventDetails() {
      this.set('bodyInput', this.model.get('description'));
      this.set('tempEventTitle', this.model.get('title'));
      this.set('tempEventStartAt', this.model.get('startAt'));
    },
    // NK: Ideally this would be a computed property, but the current
    // implementation introduces side effects, which is a no-no for
    // computed properties. Rewriting this as an observer for now, but
    // at some point when there's time (hah) this should be refactored to
    // use computed properties, so Ember is happy, which will make us all
    // happy by proxy.
    /* eslint-disable-next-line ember/no-observers */
    otherSectionChatsObserver: Ember.observer('model', function () {
      this.set('waitingForOtherSectionChats', true);
      this.set('otherSectionChats', this.eventsService.getOtherSectionChats(this.classroom, this.get('model.title'), this.get('model.startAt')).then(events => {
        this.set('hasOtherSectionChats', events.length > 0 ? true : false);
        this.set('otherSectionChatsData', events || []);
        this.set('waitingForOtherSectionChats', false);

        // Start/Joint the chat if the corresponding query param is set
        if (this.get('start_chat')) {
          this.send('startChat');
        } else if (this.get('join_chat')) {
          this.send('joinChat', this.get('join_chat'));
        }
        return Promise.resolve(events || []);
      }));
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    otherRecurringChats: Ember.computed('model', function () {
      let otherRecurringChats = this.store.peekAll('event').filter(event => {
        // Compare titles, and the starting hours and minutes of the livechats.
        /* eslint-disable-next-line ember/no-get */
        let currentEventStartAt = moment(this.get('model.startAt')),
          filteredEventStartAt = moment(event.get('startAt')),
          /* eslint-disable-next-line ember/no-get */
          titlesMatch = event.get('title') === this.get('model.title'),
          timesMatch = currentEventStartAt.hours() === filteredEventStartAt.hours() && currentEventStartAt.minutes() === filteredEventStartAt.minutes(),
          /* eslint-disable-next-line ember/no-get */
          isSameEvent = event.get('id') === this.get('model.id');
        return titlesMatch && timesMatch && !isSameEvent;
      });
      return otherRecurringChats || [];
    }),
    editorHasContent: Ember.computed('eventNotUpdated', function () {
      return !this.get('notEditingEvent') && !this.get('eventNotUpdated');
    }),
    hasRecurringChats: Ember.computed('otherRecurringChats', function () {
      let otherRecurringChats = this.otherRecurringChats;
      return otherRecurringChats.length > 0;
    }),
    async sendEmail(event, subject, startAt, templateId) {
      const instructors = this.get('session.section.teachers').map(t => ({
        name: t.name,
        image_url: t.image_url
      }));

      //Used by SendGrid to fill in e-mail placeholders.
      const dynamicTemplateData = {
        changed: true,
        courseTitle: event.courseTitle,
        instructors,
        note: this.optionalEmailNote,
        subject,
        'event': {
          title: {
            value: event.title,
            changed: !!this.titleChanged
          },
          startAt: {
            value: startAt,
            changed: !!this.startAtChanged
          },
          description: {
            value: event.description,
            changed: !!this.descriptionChanged
          },
          link: `${window.location.origin}/#/${event.courseId}/${event.courseCode}/${event.sectionId}/0/course-introduction/event/${event.id}`
        }
      };
      let studentEmails = [];
      if (this.model.privateLessonStudentId) {
        let students = this.get('session.section.students');
        let student = students.findBy('user.acsUserId', this.model.privateLessonStudentId);
        if (student) {
          studentEmails.push(student.get('user.email'));
        }
      } else {
        studentEmails = event.studentEmails;
      }
      console.log('sending e-mail to: ' + studentEmails);
      const self = this;
      Ember.$.ajax({
        type: 'POST',
        url: '/interface/email',
        data: JSON.stringify({
          to: studentEmails,
          from_name: dynamicTemplateData.courseTitle,
          subject,
          templateId,
          dynamicTemplateData
        }),
        success() {
          console.log("email success!");
        },
        error(jqXHR, textStatus) {
          (0, _dialog.default)('Notification e-mail failed to send. Try again?', ['Yes', 'No']).then(choice => {
            if (choice === 'Yes') {
              this.sendEmail.call(self, event, subject, startAt, templateId);
            }
          });
        }
      });
    },
    actions: {
      copyToClipboard: function (...text) {
        text = text.join('');
        navigator.clipboard.writeText(text);
      },
      editEvent: function (eventDescription) {
        eventDescription = eventDescription || '';
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.event-save').removeClass('hidden');

        //Prevent description editing if the event is a private lesson event. Messes things up for Jitterbit.
        if (!this.model.privateLessonStudentId) {
          // Hide the description value
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.event-description').addClass('hidden');
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.event-description-edit').removeClass('hidden');

          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.event-description-edit-action').addClass('hidden');
        }

        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.modal-content .watch-button').addClass('hidden');
        this.set('notEditingEvent', false);
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.floating-modal').removeClass('empty-evt');
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.rte-editor-input').html(eventDescription);
      },
      cancelEditEventDescription: function () {
        Ember.$('.event-save').addClass('hidden');

        //Description editing was prevented if the event is a private lesson event. Messes things up for Jitterbit.
        if (!this.model.privateLessonStudentId) {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.event-description-edit').addClass('hidden');
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.event-description').removeClass('hidden');
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.event-description-edit-action').removeClass('hidden');
        }

        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.modal-content .watch-button').removeClass('hidden');
        this.set('notEditingEvent', true);
        this.set('bodyInput', this.model.get('description'));
        this.set('tempEventTitle', this.model.get('title'));
        this.set('tempEventStartAt', this.model.get('startAt'));
      },
      updateTempDate(e) {
        let formattedDateToSave = e.target.value ? moment.tz(e.target.value, 'America/New_York').utc().format("YYYY-MM-DDTHH:mm:ss") + "Z" : "";
        this.set('tempEventStartAt', formattedDateToSave);
      },
      updateTempTitle(e) {
        this.set('tempEventTitle', e.target.value);
      },
      async saveEvent() {
        this.set('isEventSaving', true);
        let thisEvent = this.model,
          /* eslint-disable-next-line ember/no-jquery */
          newDescription = this.parseEmbed(Ember.$('.rte-editor-input').html()),
          promises = [],
          newTitle = this.get('tempEventTitle'),
          now = moment().tz('America/New_York'),
          newStartAt = this.get('tempEventStartAt');
        if (!newDescription) {
          (0, _dialog.default)('Description must not be blank.');
          this.set('isEventSaving', false);
          return;
        }
        if (!newTitle) {
          (0, _dialog.default)('Title must not be blank.');
          this.set('isEventSaving', false);
          return;
        }
        if (this.model.privateLessonStudentId && !newTitle.endsWith(_environment.default.APP.text.eventRescheduledPostfix)) {
          newTitle = newTitle + _environment.default.APP.text.eventRescheduledPostfix;
        }
        if (!moment(this.get('model.startAt')).isSame(moment(newStartAt)) && moment(newStartAt).unix() < moment(now).unix()) {
          (0, _dialog.default)('You cannot schedule a live class in the past.');
          this.set('isEventSaving', false);
          return;
        }
        const newEndAt = moment(newStartAt).add("1", "hours").utc().format('YYYY-MM-DDTHH:mm:ss') + "Z";
        let updatedEvents = [];
        if (this.hasOtherSectionChats) {
          let otherChatsData = this.otherSectionChatsData.map(chatData => {
            //update the description
            chatData.description = newDescription;
            chatData.title = newTitle;
            chatData.startAt = newStartAt;
            chatData.endAt = newEndAt;
            // wrap the chat data in an object ready for the request
            return {
              event: chatData
            };
          });
          for (let otherSectionChatData of this.otherSectionChatsData) {
            let section = await this.store.findRecord('section', otherSectionChatData.sectionId);
            updatedEvents.push({
              id: otherSectionChatData.id,
              description: otherSectionChatData.description,
              title: otherSectionChatData.title,
              courseId: otherSectionChatData.course,
              courseCode: otherSectionChatData.code,
              courseTitle: otherSectionChatData.courseTitle,
              sectionId: otherSectionChatData.sectionId,
              studentEmails: section.get('studentEmails')
            });
          }
          promises.push(this.updateNestedResources(['event', 'events'], otherChatsData, ['course', 'courses']).then(() => {
            Ember.debug('Successfully updated event in other courses');
          }));
        }
        this.startAtChanged = false;
        this.titleChanged = false;
        this.descriptionChanged = false;
        if (this.get('tempEventStartAt') != thisEvent.get('startAt')) {
          this.startAtChanged = true;
        }
        if (this.get('tempEventTitle') != thisEvent.get('title')) {
          this.titleChanged = true;
        }
        if (sanitize(this.get('bodyInput')) != sanitize(thisEvent.get('description'))) {
          this.descriptionChanged = true;
        }
        thisEvent.set('description', newDescription);
        thisEvent.set('title', newTitle);
        thisEvent.set('startAt', newStartAt);
        thisEvent.set('endAt', newEndAt);
        let course = await thisEvent.get('course');
        let sections = await course.get('sections');
        let section = sections.objectAt(0);
        let sectionId = section.get('id');
        let studentEmails = section.get('studentEmails');
        promises.push(thisEvent.save());
        updatedEvents.push({
          id: thisEvent.get('id'),
          title: thisEvent.get('title'),
          description: thisEvent.get('description'),
          courseId: course.get('id'),
          courseCode: course.get('code'),
          courseTitle: course.get('title'),
          sectionId,
          studentEmails
        });
        Promise.all(promises).then(() => {
          // Hide the textbox and save button, show the value
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.event-description-edit').addClass('hidden');
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.event-save').addClass('hidden');
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.event-description').removeClass('hidden');
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.event-description-edit-action').removeClass('hidden');
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.modal-content .watch-button').removeClass('hidden');
          Ember.$('.rte-editor-input').text('');
          this.set('notEditingEvent', true);
          /* eslint-disable-next-line ember/no-jquery */

          if (this.notifyStudents && !this.archived && course.get('isOpen')) {
            const startAt = this.model.get('formattedStartTime');
            updatedEvents.forEach(event => {
              const subject = `Updated Live Class${this.optionalEmailNote ? ' with note' : ''}: ${event.title} @ ${startAt}`;
              this.sendEmail.call(this, event, subject, startAt, _environment.default.APP.email.templates.single_live_class_generic_id);
            });
          }
          this.set('isEventSaving', false);
        }).catch(() => {
          this.set('isEventSaving', false);
          (0, _dialog.default)('There was a problem updating the live class. Please try again later.');
          if (this.model.privateLessonStudentId && newTitle.endsWith(_environment.default.APP.text.eventRescheduledPostfix)) {
            newTitle = newTitle.replace(_environment.default.APP.text.eventRescheduledPostfix, "");
            thisEvent.set('title', newTitle);
          }
        });
      },
      cancelEvent: function () {
        this.set('cancelEventConfirmedVisible', true);
        this.set('notEditingEvent', true);
      },
      cancelEventConfirmed: function (event_id) {
        // Cancel the event
        // If needed, generate and send out an announcement.
        // If needed, cancel subsequent events in the series
        // If needed, cancel other sections' chats (and other sections' repeating chats)
        let discussionsCtl = this.discussions,
          otherRecurringChats = this.otherRecurringChats,
          that = this;
        this.store.findRecord('event', event_id).then(function (eventObject) {
          let announcementData = {},
            announcement = {},
            sendCancelNotification = that.get('sendCancelNotification'),
            cancelRepeating = that.get('cancelRepeating'),
            cancelAllSections = that.get('cancelAllSections'),
            currentEventStartAt = moment(eventObject.get('startAt'));

          // start generating announcement if checked (don't send it yet)
          if (sendCancelNotification) {
            if (cancelRepeating && that.get('otherRecurringChats').length > 0) {
              announcementData.title = 'Live classes have been cancelled';
              announcementData.message = 'Several live classes titled \'' + eventObject.get('title') + '\' have been cancelled.<br><br>';
              announcementData.message += 'The cancelled live classes were scheduled for:<br><br>' + currentEventStartAt.format('MMM, D YYYY h:mm A z') + '<br>';
              announcementData.is_announcement = true;
            } else {
              announcementData = {
                message: 'A live class titled \'' + eventObject.get('title') + '\' occuring at ' + currentEventStartAt.format('MMM, D YYYY h:mm A z') + ' has been cancelled.',
                title: 'Live Class Cancelled',
                is_announcement: true
              };
            }
          }

          // delete repeating chats if checked
          if (cancelRepeating) {
            for (let i = 0; i < otherRecurringChats.length; i++) {
              let recurringEvent = otherRecurringChats[i],
                recurringEventStartAt = moment(recurringEvent.get('startAt'));
              if (currentEventStartAt < recurringEventStartAt) {
                announcementData.message += recurringEventStartAt.format('MMM, D YYYY h:mm A z') + '<br>';
                recurringEvent.destroyRecord();
              }
            }
          }

          // delete the other section chats if checked
          if (cancelAllSections) {
            let eventsToDelete = [],
              promises = [];

            // add the other sections' versions of the current event to the kill list
            for (let i = 0; i < that.otherSectionChatsData.length; i++) {
              eventsToDelete.push({
                course: that.otherSectionChatsData[i].course,
                event: that.otherSectionChatsData[i].id
              });
            }

            // if recurring, get the other sections' subsequent events
            if (cancelRepeating && otherRecurringChats.length > 0) {
              let currentEventStartAt = moment(that.get('model.startAt')),
                currentEventTitle = that.get('model.title');
              that.otherSectionChatsData.forEach(otherSectionChat => {
                // iterate through each other section where the chat occurs and add the recurring events to the list.
                let courseId = otherSectionChat.course,
                  currentEventId = otherSectionChat.id;
                /* eslint-disable-next-line ember/no-jquery */
                promises.push(Ember.$.get(`/interface/courses/${courseId}/events`).then(Ember.run.bind(results => {
                  // For each event in the other section, check if it's an instance of the repeating event.
                  let otherSectionChats = results.event,
                    filteredOtherSectionChats = [];
                  if (otherSectionChats) {
                    filteredOtherSectionChats = otherSectionChats.filter(otherSectionChat => {
                      let filteredEventStartAt = moment(otherSectionChat.startAt),
                        titlesMatch = otherSectionChat.title === currentEventTitle,
                        timesMatch = currentEventStartAt.hours() === filteredEventStartAt.hours() && currentEventStartAt.minutes() === filteredEventStartAt.minutes(),
                        isSameEvent = otherSectionChat.id === currentEventId;
                      return titlesMatch && timesMatch && !isSameEvent;
                    });
                  }

                  // add the filtered events to the to-delete list.
                  filteredOtherSectionChats.forEach(otherChat => {
                    eventsToDelete.push({
                      course: otherChat.course,
                      event: otherChat.id
                    });
                  });
                })));
              });
            }

            // Once all other sections' event data is collected, delete all the other events.
            Promise.all(promises).then(() => {
              eventsToDelete.forEach(event => {
                /* eslint-disable-next-line ember/no-jquery */
                Ember.$.ajax({
                  type: 'DELETE',
                  url: `/interface/courses/${event.course}/events/${event.event}`,
                  success: () => {
                    Ember.debug(`Deleted event ${event.event} in course ${event.course}`);
                  },
                  error: (jqXHR, textStatus, errorThrown) => {
                    Ember.debug(`Failed to delete event ${event.event} in course ${event.course}, error: ${errorThrown}`);
                    (0, _dialog.default)('There was a problem cancelling the live class in the other sections. Please cancel them manually.');
                  }
                });
              });
            });
          }

          // send out the announcment if checked
          if (sendCancelNotification) {
            announcement = that.store.createRecord('discussion', announcementData);
            announcement.save();

            // send the announcement to the other sections if we're deleting it for them too
            if (cancelAllSections) {
              announcementData = {
                discussion: announcementData
              };
              for (let i = 0; i < that.otherSectionChatsData.length; i++) {
                /* eslint-disable-next-line ember/no-jquery */
                Ember.$.ajax({
                  type: 'POST',
                  url: '/interface/sections/' + that.otherSectionChatsData[i].sectionId + '/discussions',
                  data: JSON.stringify(announcementData),
                  dataType: 'json',
                  contentType: 'application/json'
                });
              }
            }
          }

          // FINISH HIM
          eventObject.destroyRecord();
        });

        // clean up and close the modal
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.side-panel').removeClass('on-modal');
        this.set('cancelEventConfirmedVisible', false);
        if (discussionsCtl.send) {
          discussionsCtl.send('closeModals');
        }
      },
      destroyEditor: function () {
        // this gets called from the 'closeModals' function in app/mixins/enmodal.js
        this._super();
        this.set('cancelEventConfirmedVisible', false);
        this.set('notEditingEvent', true);
      }
    }
  });
});