define("bocce/components/quiz/previous-questions", ["exports", "lodash.isequal"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let PreviousQuestions = _exports.default = (_dec = Ember._action, (_class = class PreviousQuestions extends Ember.Component {
    didInsertElement() {
      var maxWidth = 0;
      $('.previous-questions .previous-question-index').each(function (index, element) {
        var curWidth = $(element).width();
        if (maxWidth < curWidth) {
          maxWidth = curWidth;
        }
      });

      // Set all buttons to the max width
      $('.previous-questions .previous-question-index').width(maxWidth);
    }
    get dropdownDisabled() {
      return this.quizzes.gradingQuiz || this.quizzes.uploadingQuestion;
    }
    get questionData() {
      return this.quizzes.questions.map((question, index) => {
        let text = `Question ${index + 1}`;
        if (this.quizzes.hasScore) {
          /**
           * Possible values for question.correct: 
           *  undefined: manually graded question without a score
           *  defined: manually graded question with a score
           *  partial: partially right answer, ex: multiple fill in the blanks 
           *          where one of the blanks is right and the other is wrong
           *  correct/incorrect: fully right or wrong answer, ex: multiple choice, multiple dropdowns
           */

          if (question.correct === 'undefined') {
            text += ` - pending`;
          } else if (question.correct === 'defined' || question.correct === 'partial') {
            text += ` - ${question.points_received} / ${question.points}`;
          } else {
            text += ` - ${question.correct}`;
          }
        } else if ((0, _lodash.default)(question.given_answer, question.default_answer) && index <= this.quizzes.maxQuestionIndexUsed && index != this.quizzes.questionIndex) {
          text += ` - unanswered`;
        }
        let disabled = this.quizzes.hasScore ? false : index > this.quizzes.maxQuestionIndexUsed;
        return {
          question,
          disabled,
          text
        };
      });
    }
    setQuestionIndex(event) {
      const index = +event.target.value;
      this.quizzes.setQuestionIndex(index);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "setQuestionIndex", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "setQuestionIndex"), _class.prototype)), _class));
});