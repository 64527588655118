define("bocce/controllers/classroom/lessons/submission-new", ["exports", "bocce/mixins/editable", "bocce/mixins/assignments", "bocce/mixins/uploadable", "bocce/mixins/audio-rec", "bocce/mixins/video-rec", "bocce/mixins/rtc-rec", "bocce/mixins/enmodal", "bocce/mixins/boot", "bocce/utilities/dialog"], function (_exports, _editable, _assignments, _uploadable, _audioRec, _videoRec, _rtcRec, _enmodal, _boot, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  var _default = _exports.default = Ember.Controller.extend(_editable.default, _assignments.default, _uploadable.default, _audioRec.default, _videoRec.default, _rtcRec.default, _boot.default, _enmodal.default, {
    init(...args) {
      this._super(...args);
    },
    userprofileService: Ember.inject.service('userprofile'),
    kalturaService: Ember.inject.service('kaltura-upload'),
    discussions: Ember.inject.controller(),
    // required by EditableMixin
    lessons: Ember.inject.controller('classroom.lessons'),
    // required by UploadableMixin
    classroom: Ember.inject.controller(),
    // required by UploadableMixin and EnmodalMixin

    actions: {
      addFile: function (file) {
        if (file.type.includes('video')) {
          let encoding_video = {
            name: file.name,
            percent_uploaded: 0
          };
          this.encoding_videos.pushObject(encoding_video);
          let fileIndex = this.encoding_videos.length - 1;
          this.kalturaUploadVideo(file, async () => {
            Ember.debug('Unable to upload video to Kaltura. Uploading to S3...');
            await (0, _dialog.default)('Video embedding unsuccessful; this file will be available for download only.', ['OK']);
            this.encoding_videos.removeObject(encoding_video);
            file.ignoreDownloadOnlyPrompt = true;
            this.send('addValidFile', file);
          }, fileIndex, URL.createObjectURL(file), encoding_video);
        } else if (this.mimeTypes.indexOf(file.type) === -1) {
          this.send('addValidFile', file);
        } else {
          this.send('addInvalidFile', file);
        }
      },
      deleteAttachment: function (file) {
        this.send('deleteFile', file);
      },
      renameAttachment: function (attachment, newName) {
        if (attachment.file?.isUrl) {
          this.kalturaService.kalturaRenameVideo(attachment.uploaded_id, newName);
          Ember.set(attachment, 'name', newName);
        } else {
          Ember.set(attachment, 'name', newName);
          this.store.findRecord('attachment', attachment.uploaded_id).then(loadedAttachment => {
            loadedAttachment.set('name', newName);
            loadedAttachment.save();
          });
        }
      }
    },
    rubric: Ember.computed('activeAssignment', function () {
      return this.get('activeAssignment.rubric');
    })
  });
});