define("bocce/components/quiz-stats/question-statistics-manual", ["exports", "bocce/utilities/dialog"], function (_exports, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  //Used for essay and file upload question types.
  //this.statistics gets passed in to the component. Origin is canvas quiz stats api.
  let QuestionStatisticsManual = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class QuestionStatisticsManual extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "session", _descriptor, this);
    }
    init(...args) {
      super.init(...args);
      this.stats = this.getStats();
    }
    getStats() {
      let stats = {};
      let scores = {
        graded: [],
        ungraded: []
      };
      let numResponses = this.statistics.responses;
      let usernames = [];
      let numUngraded = 0;
      for (const answer of this.statistics.answers) {
        for (const username of answer.user_names) {
          if (answer.id == 'ungraded') {
            scores.ungraded.push({
              name: username,
              score: -1
            });
          } else {
            scores.graded.push({
              name: username,
              score: answer.score
            });
          }
          usernames.push(username);
        }
        if (answer.id == 'ungraded') {
          numUngraded += answer.responses;
        }
      }
      const numStudents = this.session.get('section.numStudents');

      //First, we run the calculation, then we convert it to a float, then we ensure we only have two decimal places, finally, 
      //we strip off trailing zeros with the '+' at the front of this calculation. For example, the '+' makes sure that '100.00' gets stored 
      //in the string as '100', '100.10' as '100.1', etc.
      stats.percentageUngraded = `${+parseFloat(numUngraded / numResponses * 100).toFixed(2)}`;
      stats.percentageGraded = `${+parseFloat((numResponses - numUngraded) / numResponses * 100).toFixed(2)}`;
      stats.percentageSubmitted = `${+parseFloat(numResponses / numStudents * 100).toFixed(2)}`;
      stats.scores = scores;
      stats.usernames = usernames;
      stats.numResponses = numResponses;
      return stats;
    }
    showUsers(usernames) {
      let usersHtml = `<table class="question-stats-usernames">
                        <tbody>`;
      for (let user of [...new Set(usernames.sort())]) {
        usersHtml += `<tr>
                       <td>${user}</td>
                     </tr>`;
      }
      usersHtml += '</tbody></table>';
      (0, _dialog.default)('', ['OK'], usersHtml, 0);
    }
    showScores(scores, type) {
      let scoresHtml = `<table class="question-stats-usernames">
                        <tbody>`;
      if (type == 'graded') {
        scoresHtml += `
      <tr>
        <th>User</th>
        <th>Score</th>
      </tr>`;
      }
      for (let score of scores[type]) {
        scoresHtml += `<tr>
                       <td>${score.name}</td>
                       ${type == 'graded' ? `<td>${score.score}</td>` : ''}
                     </tr>`;
      }
      scoresHtml += '</tbody></table>';
      (0, _dialog.default)('', ['OK'], scoresHtml, 0, ["quiz-stats-essay-scores"]);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showUsers", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "showUsers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showScores", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "showScores"), _class.prototype)), _class));
});