define("bocce/components/character-limited-textarea", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <textarea
    class="char-limied-textarea rte-editor-input"
    value={{this.text}}
    maxlength={{this.charLimit}}
    {{on "input" this.updateText}}
  ></textarea>
  <div class="textarea-minimal-charcount">Character Count: {{this.charCount}}/{{this.charLimit}}</div>
  */
  {
    "id": "Mi6WPn2t",
    "block": "{\"symbols\":[],\"statements\":[[11,\"textarea\"],[24,0,\"char-limied-textarea rte-editor-input\"],[16,2,[32,0,[\"text\"]]],[16,\"maxlength\",[32,0,[\"charLimit\"]]],[4,[38,0],[\"input\",[32,0,[\"updateText\"]]],null],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"textarea-minimal-charcount\"],[12],[2,\"Character Count: \"],[1,[32,0,[\"charCount\"]]],[2,\"/\"],[1,[32,0,[\"charLimit\"]]],[13]],\"hasEval\":false,\"upvars\":[\"on\"]}",
    "meta": {
      "moduleName": "bocce/components/character-limited-textarea.hbs"
    }
  });
  let CharacterLimitedTextarea = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class CharacterLimitedTextarea extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "text", _descriptor, this);
    }
    get charLimit() {
      return this.args.charLimit || 500;
    }
    updateOverCharLimit() {
      if (this.charCount > this.charLimit) {
        this.args.updateOverCharLimit(true);
      } else {
        this.args.updateOverCharLimit(false);
      }
    }
    updateText(event) {
      let inputWithoutNewlines = event.target.value.replace(/[\r\n]+/gm, '');
      this.text = inputWithoutNewlines.substring(0, this.charLimit);
      if (this.args.updateBodyText) {
        this.args.updateBodyText(this.text);
      }
    }
    get charCount() {
      return this.text.length;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "text", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateOverCharLimit", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "updateOverCharLimit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateText", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "updateText"), _class.prototype)), _class));
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CharacterLimitedTextarea);
});