define("bocce/mixins/assignments", ["exports", "bocce/mixins/audio-rec", "bocce/mixins/video-rec", "bocce/mixins/rtc-rec", "bocce/mixins/boot", "bocce/mixins/showcase", "bocce/utilities/dialog", "bocce/js/utils"], function (_exports, _audioRec, _videoRec, _rtcRec, _boot, _showcase, _dialog, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = _exports.default = Ember.Mixin.create(_audioRec.default, _videoRec.default, _rtcRec.default, _showcase.default, _boot.default, {
    init(...args) {
      this._super(...args);
      this.currentTimeMetronome();
    },
    bootContent() {
      /* eslint-disable-next-line ember/no-jquery */
      let node = Ember.$('.timed-assignment-instructions');
      if (node && node.length > 0) {
        this.boot_area(node, false, true, false, false, true);
      }
    },
    grades: ['A', 'A-', 'B+', 'B', 'B-', 'C+', 'C', 'C-', 'D', 'F'],
    rubric: Ember.inject.service(),
    curgrade: false,
    // NK: I'm making this an observer for now, because this function
    // introduces side effects, which computed properties should not do.
    /* eslint-disable-next-line ember/no-observers */
    assignmentTimerObserver: Ember.observer('activeAssignment.{timed_assignment_data,timed_assignment,timed_assignment.startTime,timed_assignment.endTime}', 'currentTimePulse', function () {
      //This gets called sometimes right after 'activeAssignment' was set to false. 
      //We don't want to do anything in this case.
      if (!this.get('activeAssignment')) {
        return;
      }

      /* eslint-disable-next-line ember/no-get */
      if (!this.get('activeAssignment.timed_assignment') || !this.instructionsBooted) {
        // Force timed assignment reload
        this.activeAssignment.reload().then(() => {
          /* eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions */
          Ember.run.scheduleOnce('afterRender', this, function () {
            this.bootContent();
            this.instructionsBooted = true;
          });
        });
      }

      /* eslint-disable-next-line ember/no-get */
      let endTime = this.get('activeAssignment.timed_assignment.endTime');
      if (!endTime) {
        this.set('lockdown', false);
        this.set('videoRec', false);
        this.set('assignmentTimer', false);
        return;
      }
      let ms = new Date(endTime).getTime() - new Date().getTime();

      /* eslint-disable-next-line ember/no-get */
      let noTimer = this.get('activeAssignment.timed_assignment.no_timer');
      if (!this.get('lockdown') && ms < 0 && !noTimer) {
        this.set('lockdown', true);
      }
      if (!noTimer) {
        // Sync every 60 sec, to let the interface know the user is still active
        if (!this.syncInterval || this.syncInterval > 60) {
          this.set('syncInterval', 1);

          /* eslint-disable-next-line ember/no-jquery */
          Ember.$.ajax({
            type: 'POST',
            /* eslint-disable-next-line ember/no-get */
            url: `/interface/sections/${this.get('session.section.id')}/assignments/${this.get('activeAssignment.id')}/timer/sync_timer/`,
            dataType: 'text',
            success: () => {
              Ember.debug('Timer Synced');
            },
            error: error => {
              Ember.debug('Unable to sync timer. Something went wrong: ');
              Ember.debug(error);
            }
          });
        } else {
          this.set('syncInterval', this.syncInterval + 1);
        }
        if (!this.timerOn) {
          this.set('timerOn', true);
          this.currentTimeMetronome();
        }
      }
      if (!noTimer) {
        let seconds = Math.abs(ms / 1000);
        let hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
        seconds = seconds % 3600;
        let minutes = parseInt(seconds / 60);
        hours = hours > 0 ? hours + ':' : '';
        if (minutes < 10 && hours > 1) {
          minutes = '0' + minutes;
        }
        minutes = minutes + ':';
        seconds = Math.floor(seconds % 3600 % 60);
        if (seconds < 10) {
          seconds = '0' + seconds;
        }
        this.set('assignmentTimer', hours + minutes + seconds);
        return;
      }
    }),
    currentTimeMetronome: function () {
      var interval = 1000;
      if (this.timerOn) {
        Ember.run.later(this, function () {
          this.notifyPropertyChange('currentTimePulse');
          this.currentTimeMetronome();
        }, interval);
      }
    },
    requirementsMet: Ember.computed('activeAssignment', 'files.@each.isDone', 'postable', function () {
      return this.postable;
    }),
    /* eslint-disable-next-line ember/no-observers */
    recalcGrade: Ember.observer('openAssignmentView', 'openAssignmentView.score', function () {
      /* eslint-disable-next-line ember/no-get */
      let score = parseFloat(this.get('openAssignmentView.score'));
      if (event && event.target && event.target.getAttribute && event.target.getAttribute('type') === 'text') {
        const scoreGrade = (0, _utils.scoreToLetter)(score);
        this.set('openAssignment.score', score);
        this.set('currentGrade', score);
        this.set('openAssignmentView.grade', scoreGrade);
      }
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    canResubmit: Ember.computed('openAssignmentView', function () {
      /* eslint-disable-next-line ember/no-get */
      if (!this.classroom || !this.get('classroom.model.section.teachers').findBy('profile')) {
        return true;
      }

      /* eslint-disable-next-line ember/no-get */
      if (!this.get('classroom.model.section.teachers').findBy('profile').get('profile.can_resubmit')) {
        return true;
      }

      /* eslint-disable-next-line ember/no-get */
      return this.get('classroom.model.section.teachers').findBy('profile').get('profile.can_resubmit')[this.get('session.section.id')] !== 'no';
    }),
    // bind attachment model data to each element of an array
    bindAttachmentObjects(dataArray) {
      dataArray = dataArray ? dataArray.toArray() : [];
      for (let i = 0; i < dataArray.length; ++i) {
        let item = dataArray[i];
        // Use Ember.set and Ember.get because it's not always an EmberObject
        Ember.set(item, 'attachment_objects', []);
        if (item.attachments) {
          for (let a = 0; a < item.attachments.length; ++a) {
            let file = item.attachments[a];
            if (typeof file === 'object' && file.url) {
              Ember.set(file, 'name', file.name || file.display_name || file.filename);
              Ember.set(file, 'type', file.type || item['content-type']);
              Ember.get(item, 'attachment_objects').push(file);
            } else {
              let id = file.id || file;
              let model = this.store.findRecord('attachment', id);
              Ember.get(item, 'attachment_objects').push(model);
            }
          }
        }
      }
    },
    submissions: Ember.computed('openAssignmentView.submission_history', function () {
      let submissions = this.get('openAssignmentView.submission_history');
      if (submissions) {
        this.bindAttachmentObjects(submissions);
        return submissions;
      } else {
        return false;
      }
    }),
    // All submissions prior to the newest submission
    submissionHistory: Ember.computed('openAssignmentView.assignment.points_possible', 'submissions.[]', function () {
      var hist = this.submissions ? this.submissions.toArray().slice(0, -1) : false;
      if (!hist) {
        return false;
      }
      hist.forEach(h => {
        h.percentage = Math.round(100 * h.score / this.get('openAssignmentView.assignment.points_possible'));
      });
      return hist.reverse();
    }),
    lastSubmission: Ember.computed('submissionHistory', function () {
      let subs = this.get('submissionHistory');
      if (subs) {
        return subs[subs.length - 1];
      } else {
        return false;
      }
    }),
    // All submissions after the first submission
    resubmissions: Ember.computed('submissions.[]', function () {
      const submissions = this.submissions;

      // remove the first submission; we only want REsubmissions:
      const resubmissions = submissions.slice(1);

      // Add an index to each item, to be used to scroll to
      // different assignment submission versions
      resubmissions.forEach((submission, i) => submission.counter = i);
      return resubmissions;
    }),
    comments: Ember.computed('openAssignmentView.comments.@each.{attachments}', function () {
      /* eslint-disable-next-line ember/no-get */
      const comments = this.get('openAssignmentView.comments') || [];
      // TODO (NK): unroll this so that getting attachments isn't done
      // inside the getter. Maybe in the route?
      this.bindAttachmentObjects(comments);
      return comments;
    }),
    // A sorted array of comments and resubmissions.
    commentTimeline: Ember.computed('comments.@each.{is_deleted}', 'resubmissions.[]', function () {
      const comments = this.comments.toArray().filter(c => !c.is_deleted);
      const history = this.resubmissions.toArray();

      // Combine the two arrays
      const stream = comments.concat(history);

      // comments and submissions have different names for their
      // "created" dates. This gives us a unified sort date.
      stream.forEach(item => {
        // Uses Ember.get and Ember.set instead of item.get because not
        // all items are EmberObjects
        const date = Ember.get(item, 'created_at') || Ember.get(item, 'submitted_at');
        item.sortDate = moment(date);
      });
      stream.sort((a, b) => {
        // Uses Ember.get instead of item.get because not all items
        // are EmberObjects
        const aDate = Ember.get(a, 'sortDate');
        const bDate = Ember.get(b, 'sortDate');
        return aDate - bDate;
      });
      return stream;
    }),
    next_submission: Ember.computed('openAssignmentView', function () {
      if (!this.openAssignmentView || !this.openAssignmentView.get('data.assignment.submissions.content.content')) {
        return;
      }
      var current = this.openAssignmentView.get('data.assignment.submissions.content.content'),
        next_sub = current[current.indexOf(this.openAssignmentView) + 1] || false,
        next = next_sub.id || false;
      return next;
    }),
    prev_submission: Ember.computed('openAssignmentView', function () {
      if (!this.openAssignmentView || !this.openAssignmentView.get('data.assignment.submissions.content.content')) {
        return;
      }
      var current = this.openAssignmentView.get('data.assignment.submissions.content.content'),
        prev_sub = current[current.indexOf(this.openAssignmentView) - 1] || false,
        prev = prev_sub.id || false;
      return prev;
    }),
    openAssignmentModal: Ember.computed('activeAssignment', function () {
      return !!this.activeAssignment;
    }),
    // Router Business
    /* eslint-disable-next-line ember/no-observers */
    toggleAssignmentModal: Ember.observer('openAssignmentView', 'activeAssignment', function () {
      var assignment = this.openAssignmentView,
        activeAssignment = this.activeAssignment,
        openAssignment = this.openAssignment;
      if (assignment && openAssignment) {
        if (assignment.get('assignment').get('id') !== openAssignment.get('id')) {
          openAssignment = false;
        }
      }
      if (!!activeAssignment || !!assignment) {
        // "assignment" here is actually the submission we're viewing...
        if (assignment) {
          if (!openAssignment) {
            this.set('author', assignment.get('data.user.short_name'));
            this.set('openAssignment', assignment.get('assignment'));
            this.store.nestResources('comment', [{
              course: assignment.get('data.course.id')
            }, {
              assignment: assignment.get('data.id')
            }, {
              submission: assignment.get('data.id')
            }]);
          }
        }
        /* eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions */
        Ember.run.scheduleOnce('afterRender', this, function () {
          if (window.CameraTag) {
            window.CameraTag.setup();
          }
        });
      } else if (!activeAssignment && !assignment) {
        this.send('destroyEditor');
      }
      this.send('clearAllFiles');
    }),
    // "user" and "section" are referenced in ShowcaseMixin.
    user: Ember.computed.reads('session.user'),
    section: Ember.computed.reads('session.section'),
    postSubmissionGrade: function (grade, onSuccessCallback, onFailureCallback) {
      let sub = this.openAssignmentView,
        that = this;
      this.store.nestResources('submission', [{
        section: that.get('session.section.id')
      }, {
        assignment: sub.get('assignment.id')
      }]);
      var old_grade = sub.get('grade');
      if (grade === 'n') {
        grade = null;
        sub.set('posted_grade', '');
        sub.set('workflow_state', 'submitted');
      } else if (grade === 'r') {
        grade = null;
        sub.set('posted_grade', null);
        sub.set('teacher_requires_resubmission', 'needs_resubmit');

        // Purge timer for assignment
        Ember.$.ajax({
          type: 'POST',
          url: `/interface/sections/${that.get('session.section.id')}/assignments/${sub.get('assignment.id')}/user/${sub.get('user.id')}/purge_assignment/`,
          data: {},
          dataType: 'text',
          success: () => {
            Ember.debug('Assignments purged successfully');
          },
          error: error => {
            Ember.debug('Unable to purge assignments. Something went wrong: ');
            Ember.debug(error);
          }
        });
      } else {
        sub.set('rubric_assessment_objects', sub.get('rubric_assessments').toArray().map(a => ({
          criteria_id: a.id.split('_')[2],
          points: a.points,
          comments: a.comments
        })));
        sub.set('posted_grade', grade == 'x' ? 0 : grade);
        sub.set('workflow_state', 'graded');
        sub.set('teacher_requires_resubmission', 'submitted');
      }
      sub.save().then(sub_with_score => {
        // Success

        if (grade !== old_grade) {
          // See if we need to update the gradebook
          var gbcol = this.store.peekRecord('gradebook', sub.get('user.id')),
            assn_id = parseInt(sub.get('assignment.id'), 10);
          if (gbcol) {
            var ndx = gbcol.get('columns').findIndex(function (m) {
              return m && parseInt(m.assignment_id, 10) === assn_id;
            });
            if (ndx >= 0) {
              gbcol.set('columns.' + ndx + '.grade', grade);
              gbcol.set('columns.' + ndx + '.score', sub_with_score.get('score'));
            }
          }
        }
        onSuccessCallback?.call(this);
      }, error => {
        // Failure
        that.send('switchSubmissionGrade', grade);
        Ember.debug('Failure');
        Ember.debug(error);
        (0, _dialog.default)('Error grading assignment.');
        onFailureCallback?.call(this);
      });
      if (+grade) {
        grade = (0, _utils.scoreToLetter)(Math.round(100 * grade / sub.get('assignment.points_possible')));
      }
      sub.set('grade', grade);
      sub.set('archive_body', false);
    },
    actions: {
      assignmentSubmission: function (assignment_id) {
        this.transitionToRoute('classroom.lessons.submission-new', assignment_id);
      },
      downloadAllSubmissions: function () {
        /* eslint-disable-next-line ember/no-get */
        let url = '/courses/' + this.get('session.course.id') + '/assignments/' + this.get('openAssignmentView.assignment.id').split('-')[0] + '/submissions?zip=1';

        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.bulk_dl_message_status').text('Creating zip file');
        let requestedCompile = false,
          checkZip = () => {
            let req_url = url;
            if (!requestedCompile) {
              req_url += '&compile=1';
              requestedCompile = true;
            }

            /* eslint-disable-next-line ember/no-jquery */
            Ember.$.ajax({
              url: req_url,
              dataType: 'json',
              success: data => {
                if (data && data.attachment) {
                  if (data.attachment.workflow_state === 'zipped') {
                    /* eslint-disable-next-line ember/no-jquery */
                    Ember.$('.bulk_dl_message_status').text('');
                    window.open(url);
                    return;
                  }
                  if (data.attachment.workflow_state.indexOf('error') >= 0) {
                    /* eslint-disable-next-line ember/no-jquery */
                    Ember.$('.bulk_dl_message_status').text('Failed');

                    /* eslint-disable-next-line ember/no-jquery */
                    setTimeout(() => Ember.$('.bulk_dl_message_status').text(''), 2000);
                    return;
                  }
                }
                setTimeout(checkZip, 250);
              },
              error: () => {
                setTimeout(checkZip, 250);
              }
            });
          };
        checkZip();
      },
      markSubmissionRead: function () {
        let submission = this.openAssignmentView;
        if (!submission) return;

        // Set this locally, but use the route to save (to avoid
        // saving becoming complicated and having to guess our
        // desired operation)
        if (!submission.get('read')) {
          submission.set('read', true);
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$.get(`/interface/sections/${this.session.get('section.id')}/assignments/${submission.get('assignment.id')}/submissions/${submission.get('id')}/read`);
        }
      },
      teardown() {
        this.send('markSubmissionRead');
      },
      viewAssignmentSubmission: function (submission_id) {
        const transition = () => {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.drawer-instructions').attr('checked', false);
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('#current-student-handle').attr('checked', false);
          this.set('showcaseOpen', false);
          this.set('assetsRecovered', true);

          // Mark the submission comments as read for the submission that we're _leaving_
          this.send('markSubmissionRead');
          this.transitionToRoute('classroom.lessons.submission', submission_id);
        };
        if (this.isRubricAssessmentDirty?.()) {
          (0, _dialog.default)('Are you sure you want to switch submissions? Your changes will be lost.', ['Yes', 'No']).then(choice => {
            if (choice === 'Yes') {
              transition();
            }
          });
        } else {
          transition();
        }
      },
      deleteSubmissionBody: function () {
        (0, _dialog.default)('Are you sure you want to delete the submission body? This will also mark the submission as needing resubmission.', ['Yes', 'No']).then(choice => {
          if (choice === 'Yes') {
            this.openAssignmentView.set('archive_body', true);
            this.postSubmissionGrade('r');
          }
        });
      },
      viewInLesson: function (assignment_id) {
        var that = this;
        /* eslint-disable-next-line ember/no-get */
        this.store.findRecord('item', this.get('activeAssignment.id')).then(function (item) {
          var lessonId = item.get('lesson').id;
          if (lessonId) {
            that.transitionToRoute('classroom.lessons', lessonId, assignment_id);
          }
        }, function () {
          Ember.debug('Can\'t find lesson that contains assignment.');
        });
      },
      startTimer: function () {
        this.set('timerStarting', true);
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$.ajax({
          type: 'POST',
          /* eslint-disable-next-line ember/no-get */
          url: `/interface/sections/${this.get('session.section.id')}/assignments/${this.get('activeAssignment.id')}/timer/start_timer/`,
          data: {},
          dataType: 'text',
          success: () => {
            this.set('timerStarting', false);
            this.set('timerOn', true);
            this.set('lockdown', false);
            this.set('videoRec', false);
            this.activeAssignment.reload().then(() => {
              /* eslint-disable-next-line ember/no-get */
              let endTime = this.get('activeAssignment.timed_assignment.endTime');

              // Timer duraction calculated incorrectly - attempt to restart
              if (!endTime) {
                this.send('startTimer');
                return;
              }

              // Timer duration started at lesss than 0 seconds - attempt to restart
              let ms = new Date(endTime).getTime() - new Date().getTime();
              if (ms < 0) {
                this.send('startTimer');
                return;
              }
              this.currentTimeMetronome();

              /* eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions */
              Ember.run.later(this, function () {
                this.bootContent();
              }, 2000);
            });
            Ember.debug('Timed assignment started sucessfully');
          },
          error: error => {
            Ember.debug('Unable to start timed assignment. Something went wrong: ');
            Ember.debug(error);
          }
        });
      },
      goToAssignment: function (assignment_id) {
        let commentBody = this.bodyInput;
        let confirm;
        if (commentBody.length > 0) {
          confirm = (0, _dialog.default)('Are you sure you want to switch submissions? You will lose all unsaved data.', ['Yes', 'No']);
        } else {
          confirm = Promise.resolve('Yes');
        }
        confirm.then(choice => {
          if (choice === 'Yes') {
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.drawer-select').attr('checked', false);
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.submission-container').scrollTop(0);
            this.lessons.set('activeAssignment', false);
            this.lessons.set('openAssignment', false);
            this.lessons.set('openAssignmentView', false);
            this.send('treatAssignment', assignment_id);
          }
        });
      },
      gradeAssignmentSubmission: function (submission_url) {
        if (submission_url) {
          window.open(submission_url, '_blank');
        }
      },
      submitAssignment: function (section_id, assignment_id) {
        var content = this.bodyInput,
          sub,
          that = this;

        /* eslint-disable-next-line ember/no-jquery */
        if (Ember.$.trim(content.replace(/&nbsp;/g, ' ')).length === 0) {
          // Text is all whitespace. Don't post.
          return;
        }
        this.store.nestResources('submission', [{
          section: section_id
        }, {
          assignment: assignment_id
        }]);
        this.set('uploadInProgress', true);

        /* eslint-disable-next-line ember/no-get */
        sub = this.get('activeAssignment.data.submission');
        if (!sub) {
          sub = this.activeAssignment.store.createRecord('submission');
        }
        if (!this.working && this.file_ids.length > 0) {
          sub.set('file_ids', this.file_ids);
          sub.set('submission_type', 'online_upload');
        } else {
          sub.set('submission_type', 'online_text_entry');
        }

        // Add any video embeds
        content = this.parseEmbed(content);
        content = content + this.videoEmbedString;
        sub.set('teacher_requires_resubmission', 'submitted');
        sub.set('body', content);

        // The submission ID is derived from the assignment/user, so if
        // this is a resubmission then saving a new submission will give
        // us two models with identical IDs. To account for that, unload
        // all submissions for the current object before saving
        /* eslint-disable-next-line ember/no-get */
        let user_id = this.get('session.user.id');
        /* eslint-disable-next-line ember/no-get */
        let submissions = this.get('model.submissions');
        let my_submissions = submissions.filter(s => s.get('user.id') === user_id);
        my_submissions.forEach(s => s.unloadRecord());
        sub.save().then(function (sub) {
          that.set('timerOn', false);
          that.activeAssignment.reload();
          that.set('uploadInProgress', false);
          that.set('activeAssignment.did_submit', true);
          that.set('activeAssignment.needsStudentAttention', false);
          that.send('clearAllFiles');
          that.set('inEditor', false);
          that.set('bodyInput', '');
          that.send('destroyEditor');
          that.send('viewAssignmentSubmission', sub.id);

          // Gainsight
          let course = that.session.course,
            user = that.session.user,
            type = 'Assignment';
          that.gainsight.renderWorkGainsightPxTag(user.get('id'), course.get('id'), type, new Date());
        }, err => {
          (0, _dialog.default)('There was a problem submitting your assignment. Please retry in a moment.');
          Ember.debug(content);
          Ember.debug(this.bodyInput);
          Ember.debug(err);
          that.set('uploadInProgress', false);
        });
      },
      switchSubmissionGrade: function (grade) {
        let gradeScoreRubric = {
          'A': 100,
          'A-': 92,
          'B+': 89,
          'B': 86,
          'B-': 82,
          'C+': 79,
          'C': 76,
          'C-': 72,
          'D': 69,
          'F': 0,
          'R': 0
        };

        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.quickgrader input').removeClass('checked');
        if (grade) {
          // TODO: rewrite this feature so that we don't imperatively change the
          // selection class here. This should be doable without the runloop.
          Ember.run.schedule('afterRender', function () {
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.quickgrader input[value="' + grade + '"]').addClass('checked');
          });
          this.set('currentGrade', grade);
          this.set('openAssignmentView.score', gradeScoreRubric[grade]);
        } else {
          this.set('currentGrade', false);
        }
      },
      postSubmissionGrade: function (grade, onSuccessCallback, onFailureCallback) {
        this.postSubmissionGrade(grade, onSuccessCallback, onFailureCallback);
      },
      //dontClearBodyInput is needed because this function is called in two different places,
      //and one of them doesn't need bodyInput to be cleared.
      postSubmissionComment: function (onSuccessCallback, dontClearBodyInput) {
        var content = this.bodyInput,
          sub = this.openAssignmentView,
          submission_id = sub.get('id'),
          comment = sub.store.createRecord('comment'),
          /* eslint-disable-next-line ember/no-get */
          usr = this.get('session.user'),
          that = this,
          currentGrade;
        if (!content || content === '') {
          return;
        }

        // Add any video embeds
        content = this.parseEmbed(content);
        content = content + this.videoEmbedString;
        this.store.nestResources('submission', [{
          section: that.get('session.section.id')
        }, {
          assignment: sub.get('assignment.id')
        }]);
        this.store.nestResources('comment', [{
          section: that.get('session.section.id')
        }, {
          assignment: sub.get('assignment.id')
        }, {
          submission: submission_id
        }]);
        if (!this.working && this.file_ids.length > 0) {
          comment.set('file_ids', this.file_ids);
          comment.set('attachments', this.file_ids);
        }
        comment.set('comment', content);
        comment.set('user', usr);
        sub.get('comments').pushObject(comment);
        that.set('inEditor', false);
        if (!dontClearBodyInput) {
          that.set('bodyInput', '');
        }
        comment.save().then(function (savedComment) {
          comment.set('db_id', savedComment.get('db_id'));
          that.set('openAssignmentView', sub);

          // If there's a grade in the pipeline, post it
          let grade_to_assign = that.get('currentGrade');
          if (grade_to_assign) {
            that.set('currentGrade', false);
          } else if (!sub.get('grade_matches_current_submission')) {
            // If an instructor is leaving a comment on a resub and not changing
            // the grade, preserve it for the new submission
            grade_to_assign = sub.get('grade');
          }
          if (grade_to_assign) {
            // If the grade is a number, add a percentage sign to it, so it submits correctly...
            if (!isNaN(parseInt(grade_to_assign)) && toString(grade_to_assign).split('%').length === 1) {
              grade_to_assign = grade_to_assign + '%';
            }
            that.send('postSubmissionGrade', grade_to_assign, () => {
              onSuccessCallback?.();
            });
          } else {
            onSuccessCallback?.();
          }
          that.send('clearAllFiles');
        }, function () {
          var conv = sub.get('comments').objectAt(sub.get('comments.length') - 1);
          currentGrade = that.get('currentGrade');
          conv.set('needs_repost', true);
          conv.set('attached_grade', currentGrade);
          that.set('currentGrade', false);
        });
      },
      scrollToAssignment: function (version) {
        /* eslint-disable-next-line ember/no-jquery */
        var verNode = Ember.$('.submission-container li[version=' + version + ']');

        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.submission-container').scrollTop(0);
        if (verNode.length > 0) {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.submission-container input[version=' + version + ']').attr('checked', true);
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.submission-container').scrollTop(verNode.offset().top);
        }
      },
      repostSubmissionComment: function (comment) {
        var that = this;
        comment.set('needs_repost', false);
        comment.set('is_reposting', true);
        comment.save().then(function () {
          comment.set('needs_repost', false);
          comment.set('is_reposting', false);
          let gr = comment.get('attached_grade');
          if (gr) {
            that.send('postSubmissionGrade', gr);
          }
        }, function () {
          comment.set('needs_repost', true);
          comment.set('is_reposting', false);
        });
      },
      setAssignmentSubmissionsHiddenStatus: function (assignment, status) {
        (0, _dialog.default)('Are you sure you want to make all student submissions for this assignment viewable to all students in the class?', ['Yes', 'No']).then(choice => {
          if (choice === 'Yes') {
            let submissions = assignment.get('submissions');
            submissions.forEach(submission => {
              this.store.nestResources('submission', [{
                section: assignment.get('section.id')
              }, {
                assignment: assignment.get('id')
              }]);
              submission.set('hidden_from_students', status);
              submission.save();
            });
          }
        });
      }
    }
  });
});