define("bocce/js/quizzes/quizzes-factory", ["exports", "bocce/js/quizzes/quizzes"], function (_exports, _quizzes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class QuizzesFactory {
    static get(quizId) {
      if (this.quizzesServices[quizId]) {
        return this.quizzesServices[quizId];
      } else {
        const quizzes = new _quizzes.default();
        this.quizzesServices[quizId] = quizzes;
        return quizzes;
      }
    }
  }
  _exports.default = QuizzesFactory;
  _defineProperty(QuizzesFactory, "quizzesServices", {});
});