define("bocce/models/event", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //app/models/event
  var _default = _exports.default = _model.default.extend({
    title: (0, _model.attr)('string'),
    course: (0, _model.belongsTo)('course', {
      async: true
    }),
    startAt: (0, _model.attr)(),
    endAt: (0, _model.attr)(),
    createdAt: (0, _model.attr)(),
    description: (0, _model.attr)('string'),
    hidden: (0, _model.attr)(),
    repeatCount: (0, _model.attr)(),
    privateLessonStudentId: (0, _model.attr)(),
    user: (0, _model.belongsTo)('user', {
      async: true
    }),
    meetingKeys: (0, _model.attr)(),
    // Latest meeting key, used for hbs
    meeting_key: (0, _model.attr)(),
    meetings: (0, _model.attr)(),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    isEmpty: Ember.computed('description', 's3Recordings', function () {
      if (this.description || this.s3Recordings || this.kalturaRecordings) {
        return false;
      } else {
        return true;
      }
    }),
    s3Recordings: (0, _model.attr)(),
    kalturaRecordings: (0, _model.attr)(),
    is_updated: (0, _model.attr)('boolean'),
    currentUserIsOwner: Ember.computed('user', function () {
      let currentUser = window.session.get('user.id');
      if (this.user.get('id') !== currentUser) {
        return false;
      } else {
        return true;
      }
    }),
    startedBy: Ember.computed('user', function () {
      let eventUser = this.get('user');
      return eventUser.get('name');
    }),
    shortDescription: Ember.computed('description', function () {
      var description = this.description || '';
      var maxLength = 20;
      if (description.length > maxLength) {
        var trimmedDescription = description.substring(0, maxLength);
        return trimmedDescription = trimmedDescription.substr(0, Math.min(trimmedDescription.length, trimmedDescription.lastIndexOf(' ')));
      } else {
        return description;
      }
    }),
    formattedStartTime: Ember.computed('startAt', function () {
      var startAt = moment(this.startAt);
      return startAt.tz('America/New_York').format('MMMM Do YYYY, h:mm a') + ' ET';
    }),
    formattedCreatedTime: Ember.computed('createdAt', function () {
      let createdAt = moment(this.createdAt);
      return createdAt.tz('America/New_York').format('MMMM Do YYYY, h:mm a') + ' ET';
    }),
    dateInputStartTime: Ember.computed('startAt', function () {
      return moment(this.startAt).tz('America/New_York').format('YYYY-MM-DDTHH:mm');
    }),
    timeOnlyStartTime: Ember.computed('startAt', function () {
      var startAt = moment(this.startAt);
      return startAt.tz('America/New_York').format('h:mm a') + ' ET';
    }),
    // Hotfix
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    isMeetingSupposedToBeHappeningRightNow: Ember.computed('model.startAt', 'session.time', function () {
      let now = moment();

      /* eslint-disable-next-line ember/no-get */
      return moment(this.get('model.startAt')) <= now && /* eslint-disable-next-line ember/no-get */
      moment(this.get('model.endAt')) >= now;
    }),
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    runnable: Ember.computed('startAt', 'session.time', function () {
      let now = moment();
      return moment(this.startAt) <= now + 10 * 60 * 1000 && moment(this.endAt) >= now;
    }),
    startsWithinAWeek: Ember.computed('startAt', function () {
      let now = moment();
      return moment(this.startAt) <= now + 1000 * 60 * 60 * 24 * 7;
    })
  });
});