define("bocce/js/quizzes/quizzes", ["exports", "bocce/utilities/timer", "bocce/utilities/dialog", "bocce/utilities/promise-queue", "bocce/config/environment", "lodash.isequal"], function (_exports, _timer, _dialog, _promiseQueue, _environment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function shuffle(arr) {
    for (let i = arr.length; i > 0; --i) {
      let j = Math.floor(Math.random() * i);
      let x = arr[i - 1];
      arr[i - 1] = arr[j];
      arr[j] = x;
    }
  }
  let QuizzesService = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, (_class = class QuizzesService extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "quiz", _descriptor, this);
      _initializerDefineProperty(this, "questionIndex", _descriptor2, this);
      _initializerDefineProperty(this, "gradingQuiz", _descriptor3, this);
      _initializerDefineProperty(this, "questionShown", _descriptor4, this);
      _initializerDefineProperty(this, "maxQuestionIndexUsed", _descriptor5, this);
      _initializerDefineProperty(this, "hasShownQuizIntercept", _descriptor6, this);
      _initializerDefineProperty(this, "timeRemaining", _descriptor7, this);
      _initializerDefineProperty(this, "quizSubmittedInText", _descriptor8, this);
      _initializerDefineProperty(this, "startingQuiz", _descriptor9, this);
      _initializerDefineProperty(this, "questionPromises", _descriptor10, this);
      _initializerDefineProperty(this, "savedAt", _descriptor11, this);
      _initializerDefineProperty(this, "initialized", _descriptor12, this);
      _initializerDefineProperty(this, "uploadingQuestion", _descriptor13, this);
      _initializerDefineProperty(this, "uploadFailed", _descriptor14, this);
      _defineProperty(this, "nextQuestionCallbacks", []);
      _initializerDefineProperty(this, "showStats", _descriptor15, this);
      _defineProperty(this, "questions", void 0);
      _defineProperty(this, "timedQuizSubmissionData", void 0);
      _defineProperty(this, "performedInitialRender", void 0);
      _defineProperty(this, "session", void 0);
      _defineProperty(this, "store", void 0);
    }
    aggregateQuestions() {
      let questions = [];
      let models = ['multiple_choices', 'true_falses', 'short_answers', 'multiple_answers', 'multiple_dropdowns', 'essays', 'file_uploads', 'fill_in_multiple_blanks'];
      for (const model of models) {
        if (this.quiz.get(model)) {
          const m = this.quiz.get(model);
          m.forEach(m2 => {
            questions.push(m2);
          });
        }
      }
      this.quiz.quiz_questions = questions;
    }
    initialize(quiz, session, store, gs_service) {
      this.quiz = quiz;
      this.gainsight = gs_service;
      if (this.quiz.get('attempts.length') > 0) {
        this.quiz.set('quiz_attempt', {
          seeking: true
        });
        this.quiz.set('quiz_attempt', this.quiz.get('attempts').objectAt(0));
      }
      this.session = session;

      //Quizzes prior to the revamp had quiz questions stored in 'quiz_questions'. Post 
      //revamp, they get stored in 'multiple_choices', 'fill_multiple_blanks', etc. So, 
      //if there are 'quiz_questions', the quiz is probably archived or on the intro server. In this case, 
      //don't copy 'multiple_choices', etc. into 'quiz_questions' as they are already there.
      if (this.quiz.get('quiz_questions.length') == 0) {
        this.aggregateQuestions();
      }
      if (this.quiz.get('shuffle')) {
        let arr = this.quiz.get('quiz_questions.content.currentState');
        if (arr) {
          shuffle(arr);
          this.quiz.set('quiz_questions.content.currentState', arr);
          this.quiz.set('quiz_questions.content.canonicalState', arr);
        }
      }
      this.questions = this.get('quiz.quiz_questions').sortBy('position');
      this.questionShown = true;
      this.store = store;
      let quizAttempt = this.quiz.get('quiz_attempt');
      this.resetGivenAnswers();
      if (quizAttempt) {
        let answers = quizAttempt.get('answers') ?? null;
        if (answers) {
          this.setQuestionData(answers, true);
        }
        this.timedQuizSubmissionData = {
          id: quizAttempt.get('id'),
          token: quizAttempt.get('token'),
          number: quizAttempt.get('number')
        };

        //this.quiz.set('quiz_attempt', {left: this.quiz.get('quiz_attempt.left')});

        if (!this.hasTimeLeft || this.hasScore) {
          this.setQuestionIndex(null);
        } else {
          //find the first un-answered question and set the index equal to that.
          let firstUnansweredQuestionIndex = null;
          let lastAnsweredQuestionIndex = null;
          this.questions.forEach((question, index) => {
            if (question.hasAnswer) {
              lastAnsweredQuestionIndex = index;
            } else if (!firstUnansweredQuestionIndex) {
              firstUnansweredQuestionIndex = index;
            }
          });
          if (firstUnansweredQuestionIndex) {
            this.setQuestionIndex(firstUnansweredQuestionIndex);
          } else {
            this.setQuestionIndex(0);
          }
          if (lastAnsweredQuestionIndex && lastAnsweredQuestionIndex + 1 > firstUnansweredQuestionIndex) {
            this.maxQuestionIndexUsed = lastAnsweredQuestionIndex + 1;
            if (this.maxQuestionIndexUsed == this.questions.length) {
              this.maxQuestionIndexUsed = this.questions.length - 1;
            }
          }
        }
      } else {
        this.setQuestionIndex(this.quiz.presentation === 'single' ? 0 : null);
      }

      //Quiz is resuming. Start the timer.
      if (this.isTimedQuiz && this.hasTimeLeft && !this.hasScore) {
        this.startTimer();
      }
      this.questions.forEach(question => {
        question.quizzes = this;
      });
      Ember.run.schedule('afterRender', () => {
        this.initialized = true;
      });
    }
    resetGivenAnswers() {
      this.quiz.quiz_questions.forEach(question => {
        if (question) {
          let defaultAnswer = question.get('default_answer');
          if (defaultAnswer) {
            defaultAnswer = JSON.parse(JSON.stringify(question.get('default_answer')));
          }
          question.set('given_answer', defaultAnswer);
        }
      });
    }
    get answerStats() {
      const stats = this.quiz.question_statistics.find(qs => {
        qs.position = this.question.position;
      });
      let numResponses = stats.responses;
      let correctResponses = 0;
      let answerStats = {};
      for (let answer of stats.answers) {
        if (answer.correct) {
          correctResponses += answer.user_ids.length;
        }
        answerStats[answer.id] = {};
        answerStats[answer.id].responsePercentage = answer.user_ids.length / numResponses;
        answerStats[answer.id].userNames = answer.user_names;
      }
      return answerStats;
    }
    getAnswerStats(answerId) {
      return this.questionStatistics.answers[answerId];
    }
    get showQuizIntercept() {
      return (this.isTimedQuiz && !this.hasTimeLeft || this.previousQuestionsLocked) && !this.hasScore && !this.hasShownQuizIntercept;
    }
    get hasAttempt() {
      return this.quiz.get('quiz_attempt') != null;
    }
    get hasTimeLeft() {
      return this.quiz.get('quiz_attempt.started_at') && this.quiz.get('quiz_attempt.end_at') && moment().unix() >= moment(this.quiz.get('quiz_attempt.started_at')).unix() && moment().unix() < moment(this.quiz.get('quiz_attempt.end_at')).unix();
    }
    get isRetrying() {
      return this.get('quiz.quiz_attempt.retrying');
    }
    get quizTimeLimit() {
      const timeLimit = this.quiz.time_limit_minutes;
      return `${timeLimit} Minute${timeLimit > 1 ? 's' : ''}`;
    }
    get gradedQuestions() {
      return this.questions.filter(v => v.correct && v.correct != 'undefined');
    }
    get correctQuestions() {
      return this.questions.filter(v => v.correct && v.correct == 'correct');
    }
    get notGradedQuestions() {
      return this.questions.filter(v => v.correct && v.correct == 'undefined');
    }
    get quizNotFullyGraded() {
      return this.notGradedQuestions.length > 0;
    }
    get question() {
      if (this.questionIndex != null && this.questionIndex >= 0 && this.questionIndex < this.questions.length) {
        return this.questions.objectAt(this.questionIndex);
      } else {
        return null;
      }
    }
    get questionIndexPostfix() {
      return this.get('quiz.presentation') === 'single' ? ` of ${this.questions.length}` : '';
    }
    get isGraded() {
      return this.get('quiz.is_graded');
    }
    get score() {
      return this.get('quiz.quiz_attempt.score');
    }
    async getQuizAnswers() {
      let answers = [];
      for (let question of this.questions) {
        let answer = await question.getAnswer();
        if (!(0, _lodash.default)(answer, question.default_answer)) {
          answers.push({
            question_id: question.id,
            answer
          });
        }
      }
      return answers;
    }
    get hasScore() {
      return this.score != null;
    }
    get isTimedQuiz() {
      return this.get('quiz.time_limit_minutes') > 0;
    }
    get isScoredTimedQuizWithRemainingAttempts() {
      return this.isTimedQuiz && this.hasScore && (this.quiz.quiz_attempt.left > 0 || this.quiz.allowed_attempts == -1);
    }
    get previousQuestionsLocked() {
      return this.get('quiz.cant_go_back') && this.get('quiz.presentation') === 'single';
    }
    get timeRemainingMinutes() {
      return this.timeRemaining != null ? Math.floor(this.timeRemaining / 60) : null;
    }
    get timeRemainingSeconds() {
      return this.timeRemaining != null ? this.timeRemaining % 60 : null;
    }
    get timeRemainingFormatted() {
      if (this.timeRemainingMinutes != null && this.timeRemainingSeconds != null) {
        let minutesFormatted = new String(this.timeRemainingMinutes).padStart(2, '0');
        let secondsFormatted = new String(this.timeRemainingSeconds).padStart(2, '0');
        return `${minutesFormatted}:${secondsFormatted}`;
      } else {
        return '';
      }
    }

    /**
     * This is used by the root-level quiz template (app/templates/quiz.hbs) to render the specific
     * quiz type, of which there are five:
     * - quiz-scenarios/quiz-all-graded.hbs: all questions are shown at once for a graded quiz.
     * - quiz-scenarios/quiz-all.hbs: all questions are shown at once for a not-graded quiz.
     * - quiz-scenarios/quiz-single-graded-results.hbs: results are shown for a single-question-at-a-time graded quiz.
     * - quiz-scenarios/quiz-single-graded-reviewing.hbs: student is reviewing individual question results for a
     *                                                    single-questions-at-a-time graded quiz.
     * - quiz-scenarios/quiz-single.hbs: one question shown at a time for a not-graded quiz.
     **/
    get component() {
      const type = this.quizType;
      return type == 'dummy-component' ? type : `quiz-scenarios/${type}`;
    }
    get quizType() {
      let graded = this.hasScore;
      let presentation = this.quiz.presentation;
      let questionIndex = this.questionIndex;
      let type = 'dummy-component';
      if (this.showQuizIntercept) {
        type = 'quiz-intercept';
      } else if (presentation == 'all') {
        if (graded) {
          type = 'quiz-all-graded';
        } else {
          type = 'quiz-all';
        }
      } else if (presentation == 'single') {
        if (!graded) {
          type = 'quiz-single';
        } else if (graded && questionIndex == null) {
          type = 'quiz-single-graded-results';
        } else if (graded && questionIndex >= 0) {
          type = 'quiz-single-graded-reviewing';
        }
      }
      return type;
    }

    /**
     * Sets question data given 'answers' returned by interface.
     */
    setQuestionData(answers, updateGivenAnswer) {
      if (answers) {
        this.questions.forEach(question => {
          let answer = answers.find(({
            question_id
          }) => question_id == question.id);
          if (answer) {
            if (updateGivenAnswer && answer.answer !== undefined) {
              question.set('given_answer', answer.answer);
            }

            /**
             * Possible values for answer.correct:
             * undefined: manually graded question without a score
             * defined: manually graded question with a score
             * partial: partially right answer, ex: multiple fill in the blanks
             *          where one of the blanks is right and the other is wrong
             * correct/incorrect: fully right or wrong answer, ex: multiple choice, multiple dropdowns
             */
            question.set('correct', answer.correct);
            question.set('points_received', answer.points);
            let attachments = [];
            if (answer.attachments && answer.attachments instanceof Array && answer.attachments.length > 0) {
              attachments = answer.attachments;
            }
            let attemptData = question.get('attempt_data');
            question.set('attempt_data', {
              ...attemptData,
              attachments
            });
          }
        });
      }
    }
    addNextQuestionCallback(callback) {
      this.nextQuestionCallbacks.push(callback);
    }
    async executeNextQuestionCallbacks() {
      for (const callback of this.nextQuestionCallbacks) {
        _promiseQueue.default.enqueue(async () => {
          await callback();
        });
      }
      await _promiseQueue.default.enqueue(async () => {});
      this.nextQuestionCallbacks = [];
    }
    toggleShowStats() {
      this.showStats = !this.showStats;
    }
    async nextQuestion(index) {
      if (!this.hasScore) {
        await this.executeNextQuestionCallbacks();
      }
      this.setQuestionIndex(index);
    }
    setQuestionIndex(index) {
      this.questionIndex = index;
      if (this.maxQuestionIndexUsed == null) {
        this.maxQuestionIndexUsed = this.questionIndex;
      } else if (index > this.maxQuestionIndexUsed) {
        this.maxQuestionIndexUsed = index;
      }

      //This is a hack to ensure that didInsertElement is called when two of the same question types are repeated in a row.
      // Wasn't sure how else to make it happen.
      this.questionShown = false;
      Ember.run.schedule('afterRender', () => {
        this.questionShown = true;
      });
    }
    stopTimers() {
      if (this.autosaveTimerInterval) {
        clearInterval(this.autosaveTimerInterval);
      }
      if (this.countdownTimerInterval) {
        clearInterval(this.countdownTimerInterval);
      }
    }
    get quizLengthSeconds() {
      const timeRemaining = this.quiz.get('time_limit_minutes');
      if (this.quiz.get('quiz_attempt.end_at')) {
        //This should only be true if the user is continuing a previously started quiz
        return Math.floor((new Date(this.quiz.get('quiz_attempt.end_at')).getTime() - new Date().getTime()) / 1000);
      } else if (Number.isInteger(timeRemaining)) {
        return timeRemaining * 60;
      } else {
        return null;
      }
    }
    startTimer() {
      let submissionWarningEvents = [];
      const quizLengthSeconds = this.quizLengthSeconds;
      for (let timeLeft of _environment.default.APP.quizSubmissionWarningTimesLeft) {
        if (timeLeft < quizLengthSeconds) {
          submissionWarningEvents.push({
            time: timeLeft,
            callback: () => {
              this.quizSubmittedInText = `The quiz will be submitted in ${this.timeRemainingFormatted}`;
              this.timeRemainingSnapshot = this.timeRemaining;
              setTimeout(() => {
                this.quizSubmittedInText = undefined;
              }, 10000);
            }
          });
        }
      }
      this.countdownTimerInterval = (0, _timer.default)(this.quizLengthSeconds, 1000, (minutes, seconds) => {
        this.timeRemaining = 60 * minutes + seconds;
      }, [...submissionWarningEvents, {
        time: 0,
        callback: async () => {
          this.stopTimers();

          /**
           * Remove the retry-question-upload dialog if it is there so that we don't have two dialogs showing
           * at once when the time-is-up dialog shows.
           */
          Ember.$('.conf-dialog.retry-question-upload, .conf-dialog-mask.retry-question-upload').remove();
          await _promiseQueue.default.enqueue(async () => {
            await (0, _dialog.default)('Time is up! Click OK to submit the quiz.', ['OK'], false, false, 'quiz-time-is-up');
          });
          await this.submitQuiz(false);
        }
      }]);
    }
    async updateCanvasQuestionAnswer(question) {
      let retries = 0;
      this.uploadingQuestion = true;
      this.uploadFailed = false;
      const upload = async () => {
        try {
          const answer = await question.get('given_answer');
          const section = this.get('session.section.id');
          this.store.nestResources('attempt', [{
            section
          }, {
            quiz: this.quiz.get('id')
          }]);
          let resp = await this.store.findRecord('attempt', this.timedQuizSubmissionData.id);
          resp.set('token', this.timedQuizSubmissionData.token);
          resp.set('number', this.timedQuizSubmissionData.number);
          resp.set('answers', [{
            question_id: question.id,
            answer
          }]);
          resp.set('action', 'update_questions');
          await resp.save();
          this.savedAt = new Date().toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit'
          });
        } catch (e) {
          this.uploadFailed = true;
          if (retries < 3) {
            retries = retries + 1;
            setTimeout(upload, 1000);
          } else if (this.hasTimeLeft) {
            await (0, _dialog.default)('Unable to upload question answer. Please try again.', ['Retry'], false, false, 'retry-question-upload');
            upload();
          }
        }
        this.uploadingQuestion = false;
        this.uploadFailed = false;
      };
      await upload();
    }
    startSingleCantGoBackQuiz() {
      (0, _dialog.default)('When you answer questions, you cannot go back.', ['OK', 'Cancel']).then(choice => {
        if (choice === 'Cancel') {
          return false;
        }
        this.hasShownQuizIntercept = true;
      });
    }
    startQuiz() {
      const sectionId = this.session.get('section.id');
      const _startQuiz = () => {
        this.startingQuiz = true;
        const start = () => {
          this.hasShownQuizIntercept = true;
          this.startingQuiz = false;
        };
        if (this.isTimedQuiz) {
          Ember.$.post('/interface/sections/' + sectionId + '/quizzes/' + this.quiz.get('id') + '/attempts', data => {
            this.timedQuizSubmissionData = data;
            this.startTimer();
            start();
          }).fail(async () => {
            this.startingQuiz = false;
            const response = await (0, _dialog.default)('Problem occurred starting the quiz. Try again?', ['OK', 'Cancel']);
            if (response == 'OK') {
              _startQuiz();
            }
          });
        } else {
          start();
        }
      };
      if (this.previousQuestionsLocked) {
        (0, _dialog.default)('When you answer questions, you cannot go back.', ['OK', 'Cancel']).then(choice => {
          if (choice === 'Cancel') {
            return false;
          }
          _startQuiz();
        });
      } else {
        _startQuiz();
      }
    }
    retryQuiz() {
      Ember.set(this.quiz, 'quiz_attempt', {
        retrying: true,
        left: this.quiz.get('quiz_attempt.left')
      });
      this.setQuestionIndex(0);
      this.maxQuestionIndexUsed = 0;
      this.resetGivenAnswers();
      this.quiz.quiz_questions.forEach(question => {
        question.set('attempt_data', {});
      });
      this.hasShownQuizIntercept = false;

      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.main-panel').scrollTop(0)
      /* eslint-disable-next-line ember/no-jquery */.scrollTop(Ember.$(`#${this.get('quiz.id')}`).position().top - 10);
    }
    closeTimeRemaining() {
      this.quizSubmittedInText = undefined;
    }
    async submitQuizAttempt(data) {
      const submit = async () => {
        try {
          const section = this.get('session.section.id');
          this.store.nestResources('attempt', [{
            section
          }, {
            quiz: this.quiz.get('id')
          }]);
          let resp = await this.store.findRecord('attempt', data.id);
          let answers = await this.getQuizAnswers();
          resp.set('token', data.token);
          resp.set('number', data.number);
          resp.set('answers', answers);
          resp.set('state', 'complete');
          if (this.isTimedQuiz) {
            resp.set('action', 'submit');
          } else {
            resp.set('action', 'update_questions_and_submit');
          }
          return await resp.save();
        } catch (error) {
          console.log(`error: ${JSON.stringify(error.message)}`);
          const response = await (0, _dialog.default)('A problem occurred grading the quiz. Try again?', ['OK', 'Cancel']);
          if (response == 'OK') {
            await submit();
          }
          return null;
        }
      };
      return await submit();
    }
    async _submitQuiz() {
      if (!this.isTimedQuiz) {
        let unansweredQuestions = [];
        this.get('quiz.quiz_questions').forEach((question, index) => {
          if (!question.hasAnswer) {
            unansweredQuestions.push(index + 1);
          }
        });
        if (unansweredQuestions.length) {
          (0, _dialog.default)(`Question${unansweredQuestions.length > 1 ? 's' : ''} ${unansweredQuestions.join(', ')} ${unansweredQuestions.length == 1 ? 'is' : 'are'} unanswered.`);
          return;
        }
      }

      /* eslint-disable-next-line ember/no-get */
      var section = this.get('session.section.id');

      /* eslint-disable-next-line ember/no-jquery */
      // if ($('#' + quiz + ' .quiz-questions' + ' input[type=checkbox]:not(\'.question-checker-handle\'):not(\'.question-handle\'):checked, #' + quiz + ' .quiz-questions' + ' input[type=radio]:not(\'.question-handle\'):checked').length === 0) {
      //   return;
      // }

      let dialogPromise;
      /* eslint-disable-next-line ember/no-get */
      if (this.get('quiz.allowed_attempts') === 1) {
        dialogPromise = (0, _dialog.default)('This is your last attempt. Are you sure you want to submit? TIP: Make sure to double-check your answers!', ['Submit', 'No']);
      } else {
        dialogPromise = Promise.resolve('Submit');
      }
      let choice = await dialogPromise;
      if (choice === 'Submit') {
        /* eslint-disable-next-line ember/no-jquery */
        // $('#' + quiz + ' .question-handle:last').prop('checked', true);
        // /* eslint-disable-next-line ember/no-jquery */

        Ember.debug('Requesting token...');
        /* eslint-disable-next-line ember/no-jquery */
        const self = this;
        if (this.isTimedQuiz) {
          return await this.submitQuizAttempt(this.timedQuizSubmissionData);
        } else {
          const submit = async () => {
            return new Promise((resolve, reject) => {
              Ember.$.ajax({
                url: '/interface/sections/' + section + '/quizzes/' + this.quiz.get('id') + '/attempts',
                type: "POST",
                data: {},
                dataType: "json",
                success: async data => {
                  resolve(await this.submitQuizAttempt(data));
                },
                error: async err => {
                  self.gradingQuiz = false;
                  Ember.debug(err, 'Post quiz');
                  const response = await (0, _dialog.default)('Unable to grade quiz. Try again?', ['OK', 'Cancel']);
                  if (response == 'OK') {
                    self.gradingQuiz = true;
                    await submit();
                  } else {
                    resolve(false);
                  }
                }
              });
            });
          };
          return await submit();
        }
      }
    }
    async submitQuiz(stopTimers = true) {
      this.gradingQuiz = true;
      if (stopTimers) {
        this.stopTimers();
      }
      await this.executeNextQuestionCallbacks();
      const scrollIntoViewIfNeeded = target => {
        // Target is outside the viewport from the bottom
        if (target.getBoundingClientRect().bottom > window.innerHeight) {
          //  The bottom of the target will be aligned to the bottom of the visible area of the scrollable ancestor.
          target.scrollIntoView(false);
        }

        // Target is outside the view from the top
        if (target.getBoundingClientRect().top < 0) {
          // The top of the target will be aligned to the top of the visible area of the scrollable ancestor
          target.scrollIntoView();
        }
      };
      let finishQuizElem = document.querySelector(`#quiz-${this.quiz.id}-body .question-next-or-finish`);
      if (finishQuizElem) {
        scrollIntoViewIfNeeded(finishQuizElem);
      }
      const submit = async () => {
        try {
          let result = await this._submitQuiz();
          this.gradingQuiz = false;
          if (result) {
            this.set('quiz.quiz_attempt', result);
            this.set('quiz.todo', false);
            this.get('quiz').reload();
            if (this.questionIndex != null) {
              this.setQuestionIndex(null);
            }
            this.setQuestionData(this.get('quiz.quiz_attempt.answers'), false);
            this.quizStarted = false;

            // Gainsight
            let course = this.session.course,
              user = this.session.user,
              type = 'Quiz';
            this.gainsight.renderWorkGainsightPxTag(user.get('id'), course.get('id'), type, new Date());
          }
        } catch (e) {
          this.gradingQuiz = false;
          const response = await (0, _dialog.default)('Problem occurred grading quiz. Try again?', ['OK', 'Cancel']);
          if (response == 'OK') {
            await submit();
          }
        }
      };
      await submit();
      this.savedAt = null;
      this.timeRemaining = null;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "quiz", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "questionIndex", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "gradingQuiz", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "questionShown", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "maxQuestionIndexUsed", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "hasShownQuizIntercept", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "timeRemaining", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "quizSubmittedInText", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "startingQuiz", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "questionPromises", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "savedAt", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "initialized", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "uploadingQuestion", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "uploadFailed", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "showStats", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleShowStats", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "toggleShowStats"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "nextQuestion", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "nextQuestion"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setQuestionIndex", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "setQuestionIndex"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startQuiz", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "startQuiz"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "retryQuiz", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "retryQuiz"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeTimeRemaining", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "closeTimeRemaining"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submitQuiz", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "submitQuiz"), _class.prototype)), _class));
});