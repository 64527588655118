define("bocce/templates/components/bookmarks/bookmarks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "zxHe5i0G",
    "block": "{\"symbols\":[\"@bookmarksService\",\"@showBob\",\"@noPiano\"],\"statements\":[[11,\"button\"],[16,0,[31,[\"bookmark-manager-icon fas fa-bookmark fa-2x \",[30,[36,1],[[35,3,[\"course\",\"isGettingStarted\"]],\"hidden\"],null],\" \",[30,[36,1],[[32,3],\"no-piano\"],null],\" \",[30,[36,1],[[30,[36,2],[[32,2]],null],\"no-bob\"],null]]]],[4,[38,0],[[32,0],\"setBookmarkManagerShown\",true],[[\"target\"],[[32,1]]]],[12],[13],[2,\"\\n\"],[6,[37,1],[[32,1,[\"bookmarkManagerShown\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"div\"],[24,\"aria-hidden\",\"true\"],[24,0,\"popup-backdrop active\"],[4,[38,0],[[32,0],\"setBookmarkManagerShown\",false],[[\"target\"],[[32,1]]]],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"bookmarks floating-modal active\"],[12],[2,\"\\n        \"],[8,\"bookmarks/bookmarks-header\",[],[[\"@bookmarksService\"],[[32,1]]],null],[2,\"\\n        \"],[8,\"bookmarks/bookmarks-content\",[],[[\"@bookmarksService\"],[[32,1]]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,1],[[32,1,[\"bookmarkPreview\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"bookmarks/bookmark-preview\",[],[[\"@bookmarksService\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[32,1,[\"loadingAllBookmarks\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"bookmarks-loading-all-spinner\"],[12],[10,\"i\"],[14,0,\"fas fa-lg fa-spinner fa-spin\"],[14,\"aria-hidden\",\"true\"],[12],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"if\",\"not\",\"session\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/bookmarks/bookmarks.hbs"
    }
  });
});