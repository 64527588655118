define("bocce/utilities/promise-queue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * Taken from https://medium.com/@karenmarkosyan/how-to-manage-promises-into-dynamic-queue-with-vanilla-javascript-9d0d1f8d4df5
   * 
   * Note: don't nest PromiseQueue.enqueue - it will deadlock. So, DO NOT do the following...
   * 
   * //Bad. Will deadlock.
   * ex: await PromiseQueue.enqueue(async () => {
   *        await PromisQueue.enqueue(async () => {
   *            //Don't do this.
   *        })   
   *     })
   */
  class Queue {
    static enqueue(promise) {
      return new Promise((resolve, reject) => {
        this.queue.push({
          promise,
          resolve,
          reject
        });
        this.dequeue();
      });
    }
    static dequeue() {
      if (this.workingOnPromise) {
        return false;
      }
      const item = this.queue.shift();
      if (!item) {
        return false;
      }
      const settle = (approve, value) => {
        this.workingOnPromise = false;
        if (approve) {
          item.resolve(value);
        } else {
          item.reject(value);
        }
        this.dequeue();
      };
      try {
        this.workingOnPromise = true;
        item.promise().then(value => {
          settle(true, value);
        }).catch(err => {
          settle(false, err);
        });
      } catch (err) {
        settle(false, err);
      }
      return true;
    }
  }
  _exports.default = Queue;
  _defineProperty(Queue, "queue", []);
  _defineProperty(Queue, "pendingPromise", false);
});