define("bocce/services/legacy-attachment-manager", ["exports", "sanitize-html"], function (_exports, _sanitizeHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let LegacyAttachmentManager = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class LegacyAttachmentManager extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "rtcRec", _descriptor3, this);
      _initializerDefineProperty(this, "audioRecPanelIsActive", _descriptor4, this);
      _initializerDefineProperty(this, "audioRecPanelStatus", _descriptor5, this);
    }
    _addFile(file, valid) {
      let extension = file.name.substr((~-file.name.lastIndexOf('.') >>> 0) + 2);
      let className = `extension-${extension}`.toLowerCase();
      let record = {
        file,
        valid,
        className,
        uploaded: false,
        deleted: false
      };
      this.files.pushObject(record);
      return record;
    }
    sanitize(string) {
      const sanitizeHtmlOptions = {
        // options for the sanitize-html package. see docs:
        // https://www.npmjs.com/package/sanitize-html#htmlparser2-options
        allowedTags: _sanitizeHtml.default.defaults.allowedTags.concat(['h2', 'u']),
        transformTags: {
          'h1': 'h2',
          // Word's header is h1, but our header is h2
          'a': function (tagName, attribs) {
            // filter out useless links because apparently the geniuses
            // at Microsoft decided to start including THOSE now
            if (!attribs['href'] || attribs['href'] === '') {
              return false;
            } else {
              return {
                tagName: tagName,
                attribs: {
                  href: attribs.href
                }
              };
            }
          }
        }
      };
      return (0, _sanitizeHtml.default)(string, sanitizeHtmlOptions);
    }
    toggleCameraPanel(close) {
      if (close) {
        this.set('cameraContainerActive', false);
        return false;
      }

      /* eslint-disable-next-line ember/no-jquery */
      if (this.get('audioRecPanelIsActive')) {
        this.toggleAudioRecPanel(true);
      }
      this.set('cameraContainerActive', !this.get('cameraContainerActive'));
      if (!this.useCameraTag) {
        this.rtcRec.toggleRTCPanel(close);
        return;
      }
      if (this.get('cameraContainerActive') && !!window.CameraTag) {
        window.CameraTag.setup();
        if (window.CameraTag.cameras['recorderAtt']) {
          window.CameraTag.observe('recorderAtt', 'published', () => {
            var myCamera = window.CameraTag.cameras['recorderAtt'],
              myVideo = myCamera.getVideo(),
              mp4_url = myVideo.medias.vga_mp4,
              /* eslint-disable-next-line ember/no-jquery */
              model_title = this.get('contextTitle') || 'new-discussion',
              name = model_title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '') + '_video_' + new Date().getTime(),
              myVid = {
                isUrl: true,
                name: name,
                url: mp4_url,
                type: 'video/mp4'
              },
              cas;
            if (mp4_url) {
              myCamera.reset();
              // Add video to archive
              if (localStorage.localArchive) {
                cas = JSON.parse(localStorage.localArchive);
              } else {
                cas = {};
              }
              cas[myVid.name] = myVid;
              localStorage.localArchive = JSON.stringify(cas);
              this.set('cameraContainerActive', false);
              this.addValidFile(myVid);
            }
          }, true);
        }
      }
    }
    toggleAudioRecPanel(close) {
      if (!!close || this.audioRecPanelIsActive || this.noRec) {
        this.audioRecPanelIsActive = false;
        this.audioRecPanelStatus = 'standby';
        return false;
      }
      if (this.audioRecPanelIsActive || !!close && !!this.currStream) {
        this.recorder.destroy();
        this.set('recorder', false);
      } else {
        this.audioRecPanelIsActive = true;
        this.audioRecPanelStatus = 'standby';
        this.set('cameraContainerActive', false);
        try {
          // WebKit nonsense
          window.AudioContext = window.AudioContext || window.webkitAudioContext;
          window.URL = window.URL || window.webkitURL;
          Ember.debug('Audio context set up.');
          Ember.debug('navigator.getUserMedia ' + (navigator.getUserMedia ? 'available.' : 'not present!'));
        } catch (e) {
          Ember.debug('No web audio support in this browser!');
        }
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "rtcRec", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "audioRecPanelIsActive", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "audioRecPanelStatus", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'standby';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "sanitize", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "sanitize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleCameraPanel", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "toggleCameraPanel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleAudioRecPanel", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "toggleAudioRecPanel"), _class.prototype)), _class));
});