define("bocce/components/attachments/attached-file", ["exports", "@glimmer/component", "bocce/helpers/file-extension", "bocce/utilities/dialog"], function (_exports, _component, _fileExtension, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AttachmentsAttachedFileComponent = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class AttachmentsAttachedFileComponent extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      _initializerDefineProperty(this, "renaming", _descriptor, this);
      _initializerDefineProperty(this, "renameInput", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      this.renaming = false;
      if (this.fileName && this.fileName.includes(".")) {
        this.renameInput = this.fileName.split(".")[0];
      } else if (this.fileName && this.fileName.length > 0) {
        this.renameInput = this.fileName;
      } else {
        this.renameInput = "";
      }
    }
    get progress() {
      if (this.args.attachment.progress) {
        return this.args.attachment.progress;
      }
      return 0;
    }
    get fileName() {
      return this.args.name || this.args.attachment.name || this.args.attachment.file.name;
    }
    get fileExtension() {
      return (0, _fileExtension.fileExtension)([this.fileName]);
    }
    get uploading() {
      if (this.args.uploading) {
        return this.args.uploading;
      }
      return false;
    }
    get uploaded() {
      if (this.args.attachment?.uploaded) {
        return this.args.attachment.uploaded;
      }
      return false;
    }
    get fileUrl() {
      return this.args.attachment.blobURL || URL.createObjectURL(this.args.attachment.file);
    }
    get attachmentType() {
      if (this.args.attachment.file?.type?.includes('audio')) {
        return "microphone";
      } else if (this.args.attachment.file?.type?.includes('video')) {
        return "video";
      } else {
        return "paperclip";
      }
    }
    deleteAttachment() {
      let dialogMessage = 'Are you sure you wish to delete this attachment?';
      (0, _dialog.default)(dialogMessage, ['Yes', 'No']).then(selection => {
        if (selection === 'Yes') {
          this.args.deleteAttachment(this.args.attachment.file);
        }
      });
    }
    showRename() {
      this.renaming = true;
    }
    confirmRename() {
      if (!this.renameInput || this.renameInput.includes('.')) {
        alert('Invalid file name, must not contain "."');
        return;
      }
      let newName = this.fileExtension ? this.renameInput + this.fileExtension : this.renameInput;
      this.args.renameAttachment(this.args.attachment, newName, this.args.index);
      this.renaming = false;
    }
    cancelRename() {
      this.renaming = false;
    }
    retryAttachment() {
      this.args.uploadSingleFile(this.args.attachment.file);
    }
    playPreviewAudio(url) {
      let audioPlayer = document.getElementById('main-audio-player');
      let audioPlayerHTML = audioPlayer.querySelector('audio');
      audioPlayer.classList.add('active');
      audioPlayerHTML.src = url;
      audioPlayerHTML.play();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "renaming", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "renameInput", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "deleteAttachment", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "deleteAttachment"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showRename", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "showRename"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "confirmRename", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "confirmRename"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelRename", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "cancelRename"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "retryAttachment", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "retryAttachment"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "playPreviewAudio", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "playPreviewAudio"), _class.prototype)), _class));
});