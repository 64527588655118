define("bocce/components/inbox/message-tile", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let MessageTile = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.computed('conversation', 'conversation.messages.[]', 'conversation.messages.length'), _dec3 = Ember.computed('conversation', 'conversation.message_count'), _dec4 = Ember.computed('conversation', 'conversation.conversationPartners.[]'), _dec5 = Ember.computed('args.conversation.conversationPartners.[]'), _dec6 = Ember.computed('this.args.conversation', 'this.args.conversation.isRead'), _dec7 = Ember.computed('this.args.conversation', 'this.args.conversation.conversationPartners'), _dec8 = Ember._action, (_class = class MessageTile extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
    }
    get isValidMessage() {
      return this.args.conversation.conversationPartners.length > 0;
    }
    get replyCount() {
      let replyCount = this.args.conversation.message_count ? this.args.conversation.message_count - 1 : 0;
      if (replyCount > 0) {
        return replyCount;
      }
      return false;
    }
    get threePlusParticipants() {
      let allParticipants = this.args.conversation ? this.args.conversation.conversationPartners.toArray() : [];
      let totalParticipants = allParticipants.length;
      if (totalParticipants > 2) {
        return true;
      }
    }
    get enrichedConversationPartners() {
      return this.args.conversation.conversationPartners.map((partner, index, partners) => {
        return {
          ...partner,
          isLast: index === partners.length - 1
        };
      });
    }
    get conversationRead() {
      return this.args.conversation ? this.args.conversation.isRead : false;
    }
    get conversationPartners() {
      let allParticipants = this.args.conversation ? this.args.conversation.conversationPartners.toArray() : [];
      let totalParticipants = allParticipants.length;
      allParticipants[totalParticipants - 1].isLast = true;
      if (totalParticipants <= 2) {
        return allParticipants;
      }

      // Only keep the first 2 participants
      let partners = allParticipants.slice(0, 2);
      let moreCount = allParticipants.length - 2;
      let placeholderObject = {
        isPlaceholder: true,
        moreCount: moreCount
      };
      partners.push(placeholderObject);
      return partners;
    }
    viewConversation(conversation) {
      let isNew = this.args.conversation.isNew;
      if (isNew) {
        this.args.updateSelectedConversation(false, true, conversation);
      } else {
        this.args.updateSelectedConversation(conversation.id);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isValidMessage", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isValidMessage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "replyCount", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "replyCount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "threePlusParticipants", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "threePlusParticipants"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "enrichedConversationPartners", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "enrichedConversationPartners"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "conversationRead", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "conversationRead"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "conversationPartners", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "conversationPartners"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "viewConversation", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "viewConversation"), _class.prototype)), _class));
});