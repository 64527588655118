define("bocce/controllers/classroom/lessons/submission", ["exports", "bocce/mixins/editable", "bocce/mixins/assignments", "bocce/mixins/uploadable", "bocce/mixins/audio-rec", "bocce/mixins/video-rec", "bocce/mixins/rtc-rec", "bocce/mixins/enmodal", "bocce/mixins/boot", "bocce/mixins/discussable", "bocce/utilities/dialog"], function (_exports, _editable, _assignments, _uploadable, _audioRec, _videoRec, _rtcRec, _enmodal, _boot, _discussable, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  /* eslint-disable-next-line ember/no-mixins */
  var _default = _exports.default = Ember.Controller.extend(_enmodal.default, _editable.default, _assignments.default, _uploadable.default, _audioRec.default, _videoRec.default, _rtcRec.default, _boot.default, _discussable.default, {
    userprofileService: Ember.inject.service('userprofile'),
    kalturaService: Ember.inject.service('kaltura-upload'),
    rubricService: Ember.inject.service("rubric"),
    discussions: Ember.inject.controller(),
    // required by EditableMixin
    lessons: Ember.inject.controller('classroom.lessons'),
    // required by UploadableMixin
    classroom: Ember.inject.controller(),
    // required by UploadableMixin and EnmodalMixin
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    assignments: Ember.computed(function () {
      return this.store.findAll('assignment');
    }),
    isShowingAssignmentDetails: false,
    bootContent() {
      /* eslint-disable-next-line ember/no-jquery */
      let node = Ember.$('.submission-body');
      if (node && node.length > 0) {
        this.boot_area(node, false, true, false, false, true);
      }
    },
    get editorHasContent() {
      const hasContent = this.bodyInputHasContent;
      const hasAttachments = this.hasNonDeletedAttachments;
      return hasContent || hasAttachments || this.isRubricAssessmentDirty?.();
    },
    actions: {
      addFile: function (file) {
        if (file.type.includes('video')) {
          let encoding_video = {
            name: file.name,
            percent_uploaded: 0
          };
          this.encoding_videos.pushObject(encoding_video);
          let fileIndex = this.encoding_videos.length - 1;
          this.kalturaUploadVideo(file, async () => {
            Ember.debug('Unable to upload video to Kaltura. Uploading to S3...');
            await (0, _dialog.default)('Video embedding unsuccessful; this file will be available for download only.', ['OK']);
            this.encoding_videos.removeObject(encoding_video);
            file.ignoreDownloadOnlyPrompt = true;
            this.send('addValidFile', file);
          }, fileIndex, URL.createObjectURL(file), encoding_video);
        } else if (this.mimeTypes.indexOf(file.type) === -1) {
          this.send('addValidFile', file);
        } else {
          this.send('addInvalidFile', file);
        }
      },
      deleteAttachment: function (file) {
        this.send('deleteFile', file);
      },
      renameAttachment: function (attachment, newName) {
        if (attachment.file?.isUrl) {
          this.kalturaService.kalturaRenameVideo(attachment.uploaded_id, newName);
          Ember.set(attachment, 'name', newName);
        } else {
          Ember.set(attachment, 'name', newName);
          this.store.findRecord('attachment', attachment.uploaded_id).then(loadedAttachment => {
            loadedAttachment.set('name', newName);
            loadedAttachment.save();
          });
        }
      },
      requestResubmit() {
        (0, _dialog.default)('Are you sure you want to request a resubmission?', ['Yes', 'No']).then(choice => {
          if (choice.indexOf('Yes') === 0) {
            this.postSubmissionGrade('r');
          }
        });
      },
      setRubricAssessmentIsDirtyCallback(isRubricAssessmentDirty) {
        this.isRubricAssessmentDirty = isRubricAssessmentDirty;
      },
      onSubmitGrade(grade, onCompleteCallback) {
        this.set('session.isWorking', true);
        this.postSubmissionGrade(grade, () => {
          this.actions.postSubmissionComment.call(this, () => {
            this.set('session.isWorking', false);
            this.set('bodyInput', '');
            onCompleteCallback();
          }, true);
        }, () => {
          this.set('session.isWorking', false);
        });
      },
      toggleIsShowingAssignmentDetails() {
        this.set('isShowingAssignmentDetails', !this.get('isShowingAssignmentDetails'));
      },
      deleteHistoricSubmission(attemptNum, historicAttachments, userId) {
        const message = 'Are you sure you wish to delete this past submission? This action CANNOT be undone!';
        const submissionDbId = this.get('openAssignmentView.db_id');
        (0, _dialog.default)(message, [`Yes. But, before you click this button, first perform any manual file deletion in S3 and Canvas (in that order). submission db id: ${submissionDbId}`, 'No']).then(choice => {
          if (choice.indexOf('Yes') === 0) {
            /* eslint-disable-next-line ember/no-get */
            const sectionId = this.get('session.section.id');
            /* eslint-disable-next-line ember/no-get */
            const assignmentId = this.get('openAssignmentView.assignment.content.id').split('-')[0];
            /* eslint-disable-next-line ember/no-get */

            const self = this;
            Ember.$.post(`/interface/sections/${sectionId}/assignments/${assignmentId}/submissions/${submissionDbId}/delete_historic_submission`, JSON.stringify({
              attempt: attemptNum,
              userId,
              attachments: historicAttachments ? historicAttachments.map(ha => ha.id).join(',') : []
            }), () => {
              const submission = self.submissions.find(item => {
                return item.id = submissionDbId && item.attempt === attemptNum;
              });
              self.submissions.removeObject(submission);
            }).fail(function () {
              (0, _dialog.default)('Problem occurred deleting historic submission. Please reload the page and try again.');
            });
          }
        });
      },
      deleteAssignmentComment(postID) {
        (true && !(Boolean(postID)) && Ember.assert('postID should exist', Boolean(postID)));
        /* eslint-disable-next-line ember/no-get */
        const section = this.get('session.section.id');
        /* eslint-disable-next-line ember/no-get */
        const assignment = this.get('openAssignmentView.assignment.content.id').split('-')[0];
        /* eslint-disable-next-line ember/no-get */
        const submission = this.get('openAssignmentView.id');
        (true && !(section) && Ember.assert('section id exists', section));
        (true && !(assignment) && Ember.assert('assignment id exists', assignment));
        (true && !(submission) && Ember.assert('submission id exists', submission));
        this.store.nestResources('comment', [{
          section
        }, {
          assignment
        }, {
          submission
        }]);
        this.store.findRecord('comment', postID).then(function (record) {
          record.set('is_deleted', true);
          record.save();
        });
      },
      deleteSubmissionAttachment(sectionId, submissionId, assignmentDbId, userId, attachmentId) {
        const message = 'Are you sure you wish to delete this attachment? This action CANNOT be undone!';
        const self = this;
        (0, _dialog.default)(message, [`Yes. But, before you click this button, first perform any manual file deletion in S3 and Canvas (in that order). attachment id: ${attachmentId}`, 'No']).then(choice => {
          if (choice.indexOf('Yes') === 0) {
            let destroy = false;
            self.set('session.isWorking', true);
            this.store.findRecord('attachment', attachmentId).then(record => {
              this.store.nestResources('attachment', [{
                section: sectionId
              }, {
                assignment: assignmentDbId
              }, {
                submission: submissionId
              }, {
                user: userId
              }, {
                destroy
              }]);
              try {
                record.destroyRecord().then(() => {
                  if (submissionId) {
                    this.store.findRecord('submission', submissionId).then(function (record) {
                      let currentAttachmentIds = record.get('attachments');
                      record.set('attachments', currentAttachmentIds.filter(a => a != attachmentId));
                      self.set('session.isWorking', false);
                    });
                  } else {
                    self.set('session.isWorking', false);
                    //submissionId is not defined if we are deleting an attachment for submission history
                  }
                  this.store.nestResources('attachment', []);
                }).catch(() => {
                  (0, _dialog.default)('Problem occurred deleting attachment. Please reload the page and try again.');
                  self.set('session.isWorking', false);
                });
              } catch (e) {
                (0, _dialog.default)('Problem occurred deleting attachment. Please reload the page and try again.');
                self.set('session.isWorking', false);
              }
            });
          }
        });
      },
      destroyEditor: function () {
        /**
         * Note: this is currently called twice. Once in the assignments mixin and once in 'closeModals'.
         */
        this._super();

        //This is here so that the 'toggleAssignmentModal' observer gets triggered each time opening up the assignment modal. This
        //in turn ensures that the needed assignment data gets set.
        this.set('openAssignmentView', false);
      },
      deleteSubmissionCommentAttachment(sectionId, commentId, assignmentId, userId, attachmentId) {
        const message = 'Are you sure you wish to delete this attachment? This action CANNOT be undone!';
        const self = this;
        (0, _dialog.default)(message, [`Yes. Soft delete.`, `Yes. Leave no trace. But, before you click this button, permanent deletion in S3 must be performed manually. Attachment id: ${attachmentId}`, 'No']).then(choice => {
          if (choice.indexOf('Yes') === 0) {
            let destroy = choice === 'Yes. Leave no trace.';
            self.set('session.isWorking', true);
            this.store.findRecord('attachment', attachmentId).then(record => {
              this.store.nestResources('attachment', [{
                section: sectionId
              }, {
                assignment: assignmentId
              }, {
                comment: commentId
              }, {
                user: userId
              }, {
                destroy
              }]);
              try {
                record.destroyRecord().then(() => {
                  this.store.findRecord('comment', commentId).then(function (record) {
                    let currentAttachmentIds = record.get('attachments');
                    record.set('attachments', currentAttachmentIds.filter(a => a != attachmentId));
                    self.set('session.isWorking', false);
                  });
                  this.store.nestResources('attachment', []);
                }).catch(() => {
                  (0, _dialog.default)('Problem occurred deleting attachment. Please reload the page and try again.');
                  self.set('session.isWorking', false);
                });
              } catch (e) {
                (0, _dialog.default)('Problem occurred deleting attachment. Please reload the page and try again.');
                self.set('session.isWorking', false);
              }
            });
          }
        });
      }
    }
  });
});